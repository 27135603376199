import {
  COMPANY_DETAILS_PUT_OK,
  COMPANY_GET,
  COMPANY_GET_FAIL,
  COMPANY_GET_OK,
  COMPANY_TERMS_PUT_OK
} from "../actions";

const initialState = {
  data: null,

  loading: false,
  loaded: false,

  error: false,
  errorMessage: null,
};


export default function reducer(state = initialState, action) {
  switch (action.type) {

    case COMPANY_GET: {
      return {
        ...state,
        data: null,
        loading: true,
        loaded: false,
        error: false
      };
    }

    case COMPANY_GET_OK: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload
      };
    }

    case COMPANY_DETAILS_PUT_OK: {
      return {
        ...state,
        data: {
          ...state.data,
          completed: true,
          details: action.payload
        }
      }
    }

    case COMPANY_TERMS_PUT_OK: {
      return {
        ...state,
        data: {
          ...state.data,
          agreedToTerms: true
        }
      };
    }

    case COMPANY_GET_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        errorMessage: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

