import React, {Component} from 'react'
import {connect} from 'react-redux';
import {GAME_LIST} from "../../actions";
import Loading from "../../ui/loading";
import {withTranslation} from "react-i18next";
import {Button, Container, Divider, Icon, Image} from "semantic-ui-react";
import {Link} from "react-router-dom";
import {toastr} from 'react-redux-toastr';


class GameDetails extends Component {


  componentDidMount() {
    const {companies, games} = this.props;
    if (!games.loaded && !games.loading) {
      this.props.dispatch({
        type: GAME_LIST,
        companyKey: companies.currentCompanyKey
      });
    }
    window.scrollTo(0, 0);
  }

  copyToClipboard = (url) => {
    console.log(url);
    navigator.clipboard.writeText(url);
    toastr.success('Game URL copied to clipboard!');

  };

  render() {
    const {t, games} = this.props;
    const {gameKey} = this.props.match.params;
    if (!games.loaded) {
      return <Loading/>
    }

    const game = games.data[gameKey];

    let image = game.image;
    if (image === null) {
      image = game.thumbnail;
    }


    return (
      <Container text className='page'>
        <Link to={'/games'}>
          <Icon name='arrow left' size='large'/>
        </Link>
        <Divider hidden/>

        <Image src={image} fluid/>

        <h1>{game.title}</h1>
        <p>{game.description}</p>
        <Divider hidden/>
        {game.previewUrl &&
        <a href={game.previewUrl} target='_blank'>
          <Button positive size='large'>
            <Icon name='gamepad'/>{t('games.preview.button.title')}
          </Button>
        </a>
        }
        {/*<Button size='large'>*/}
        {/*  <Icon name='copy'/>{t('games.embed.button.title')}*/}
        {/*</Button>*/}
        <Button positive size='large' onClick={() => this.copyToClipboard(game.url)}>
          <Icon name='copy'/>{t('games.url.button.title')}
        </Button>
        {game.mediaKit &&
        <a href={game.mediaKit} target='_blank'>
          <Button positive size='large'>
            <Icon name='copy'/>{t('games.download.button.title')}
          </Button>
        </a>
        }
      </Container>
    );
  }
}


const mapStateToProps = ({companies, games}) => ({companies, games});
export default connect(mapStateToProps)(withTranslation()(GameDetails));