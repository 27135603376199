import React, {Component, Fragment} from 'react'
import {Container, Icon, Table} from "semantic-ui-react";
import {ECPM_DIVIDER, REVENUE_DIVIDER} from "../../settings";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import Loading from "../../ui/loading";
import {REPORT_BY_GAME_LIST} from "../../actions";
import {CSVLink} from 'react-csv';
import ReportRow from "./ui/report-row";
import ReportSum from "./ui/report-sum";


class ReportsByGame extends Component {

  componentDidMount() {
    const {companies: {currentCompanyKey}, reportsByGame, startDate, endDate, dispatch} = this.props;
    if (!reportsByGame.loaded) {
      dispatch({
        type: REPORT_BY_GAME_LIST,
        currentCompanyKey, startDate, endDate
      });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {companies: {currentCompanyKey}, startDate, endDate, dispatch} = this.props;
    const {startDate: prevStartDate, endDate: prevEndDate} = prevProps;
    if (startDate !== prevStartDate || endDate !== prevEndDate) {
      dispatch({
        type: REPORT_BY_GAME_LIST,
        currentCompanyKey, startDate, endDate
      });
    }
  }

  render() {
    const {t, reportsByGame} = this.props;

    if (!reportsByGame.loaded) {
      return <Loading/>;
    }

    let rows = [];
    let csvData = [];

    let totalImpressions = 0;
    let totalNetRevenue = 0;
    let totalAverageECPM = 0;
    let totalAverageECPMCount = 0;

    Object.keys(reportsByGame.data.games).map((key, index) => {
      const report = reportsByGame.data.games[key];

      totalImpressions += report.impressions ? report.impressions : 0;
      totalNetRevenue += report.revenue ? report.revenue : 0;
      totalAverageECPM += report.ecpm ? report.ecpm : 0;
      totalAverageECPMCount += report.ecpm ? 1 : 0;

      csvData.push({
        title: report.game.title,
        impressions: report.impressions,
        revenue: report.revenue / REVENUE_DIVIDER,
        ecpm: report.ecpm / ECPM_DIVIDER
      });

      rows.push(<ReportRow key={index} report={report}/>);
    });
    totalAverageECPM /= totalAverageECPMCount;

    return (
      <Fragment>
        <Container align={'right'}>
          <CSVLink
            className='download-csv'
            headers={[
              {label: t('main.gameTitle'), key: "title"},
              {label: t('charts.impressions.title'), key: "impressions"},
              {label: t('charts.netRevenue.title'), key: "revenue"},
              {label: t('charts.averageECPM.title'), key: "ecpm"}
            ]}
            data={csvData}
            filename='games.csv'>
            <Icon name='download'/>{t('main.downloadCSV')}
          </CSVLink>
        </Container>
        <Table celled striped>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{t('main.gameTitle')}</Table.HeaderCell>
              <Table.HeaderCell>{t('charts.impressions.title')}</Table.HeaderCell>
              <Table.HeaderCell>{t('charts.netRevenue.title')}</Table.HeaderCell>
              <Table.HeaderCell>{t('charts.averageECPM.title')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows}
          </Table.Body>
          <Table.Footer>
            {<ReportSum
              t={t}
              totalImpressions={totalImpressions}
              totalNetRevenue={totalNetRevenue}
              totalAverageECPM={totalAverageECPM}
            />}
          </Table.Footer>
        </Table>
      </Fragment>
    );
  }
}

const mapStateToProps = ({companies, reportsByGame}) => ({
  companies,
  reportsByGame
});
export default connect(mapStateToProps)(withTranslation()(ReportsByGame));