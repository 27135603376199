import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {AUTH_LOGIN} from "../../actions";
import {Link, Redirect} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Image
} from "semantic-ui-react";
import {required} from '../../helpers/validation';
import ErrorMessage from "../../ui/error-message";
import {InputField} from "../../helpers/forms";
import CoolGamesLogo from '../../static/img/logo-coolgames-beta.png';


class Login extends Component {

  constructor(props) {
    super(props);
    this.state = {isAuthenticated: false, user: null, token: ''};
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin(values) {
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: AUTH_LOGIN,
        data: values,
        resolve,
        reject
      });
    }).catch((error) => {
      let _error = null;
      if (error.errors.nonFieldErrors) {
        _error = error.errors.nonFieldErrors[0];
      }
      throw new SubmissionError({...error.errors, _error});
    });
  }


  render() {
    const {t, auth, handleSubmit, pristine, submitting, error} = this.props;
    const {from} = this.props.location.state || {from: {pathname: '/'}};
    if (auth.authenticated) {
      return (
        <Redirect to={from}/>
      );
    }

    return (
      <Container text className='page' style={{paddingTop: '5em'}}>
        <Image src={CoolGamesLogo} size='medium' centered/><br/>
        <Header as='h2'>{t('authentication.title')}</Header>
        <p>{t('authentication.text')}</p>
        <Divider/>
        <Form onSubmit={handleSubmit(this.handleLogin)} noValidate>
          <Field
            required={true}
            name='username'
            type='text'
            component={InputField}
            validate={required}
            label={t('authentication.form.username')}
            placeholder='Username'/>

          <Field
            required={true}
            name='password'
            type='password'
            component={InputField}
            validate={required}
            label={t('authentication.form.password')}
            placeholder='Password'/>

          {error &&
          <ErrorMessage message={error}/>
          }
          <Link to='/forgot-password'>
            {t('authentication.buttons.forgotPassword')}
          </Link>

          <Button
            positive
            floated='right'
            loading={submitting}
            disabled={pristine || submitting}>
            {t('authentication.buttons.login')}
          </Button>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = ({auth}) => ({auth});
const loginForm = reduxForm({
  form: 'loginForm',
  touchOnBlur: false
})(Login);
export default connect(mapStateToProps)(withTranslation()(loginForm));




