import React, {Component} from 'react';
import {Button, Modal} from "semantic-ui-react";
import i18n from "../i18n";
import {Link} from "react-router-dom";


export default class ConfirmModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle(e) {
    e.preventDefault();
    e.stopPropagation();
    this.setState({open: !this.state.open});
  }

  render() {
    const {
      buttonText,
      button = true,
      confirmText,
      confirmTitle,
      confirmButtonText,
      confirmedAction
    } = this.props;

    const {open} = this.state;

    let trigger = <Link to='#' className='item'>{i18n.t(buttonText)}</Link>;
    if (button) {
      trigger = <Button size='mini' floated='left' negative>
        {i18n.t(buttonText)}
      </Button>;
    }

    return (
      <Modal
        open={open}
        onOpen={this.toggle}
        onClose={this.toggle}
        size='mini'
        centered={false}
    
        closeOnEscape={true}
        closeOnDimmerClick={true}
        trigger={trigger}>

        <Modal.Header>{i18n.t(confirmTitle)}</Modal.Header>
        <Modal.Content>
          <p>{i18n.t(confirmText)}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.toggle}>{i18n.t('buttons.cancel')}</Button>
          <Button
            negative
            onClick={(e) => {
              e.preventDefault();
              confirmedAction();
            }}>
            {i18n.t(confirmButtonText)}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
