import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux';
import {GAME_CATEGORY_LIST, GAME_LIST} from "../../actions";
import Loading from "../../ui/loading";
import {withTranslation} from "react-i18next";
import {Card} from "semantic-ui-react";
import logo from '../../static/img/logo-coolgames.png';
import {Link} from "react-router-dom";


class GameCard extends Component {
  render() {
    const {t, game} = this.props;
    if(game === undefined) {
      return null; // draft game
    }
    let image = game.thumbnail;
    if (game.thumbnail === null) {
      image = logo;
    }
    return (
      <Card as={Link} to={`/games/${game.key}`}>
        <div className="image">
          <img src={image} alt={game.title} style={{
            height: '175px',
            width: '100%',
          }}/>
        </div>
        <Card.Content>
          <Card.Header>{game.title}</Card.Header>
          <Card.Description>
            {game.description}
          </Card.Description>
        </Card.Content>
      </Card>
    )
  }
}

class GameList extends Component {

  render() {
    const {t, gameKeys, games} = this.props;

    return <Card.Group doubling itemsPerRow={4}>
      {gameKeys.map((key, index) => {
        return <GameCard game={games.data[key]} key={index} t={t}/>
      })}
    </Card.Group>
  }
}


class Games extends Component {

  componentDidMount() {
    const {companies, games, gameCategories} = this.props;
    if (!gameCategories.loaded && !gameCategories.loading) {
      this.props.dispatch({type: GAME_CATEGORY_LIST});
    }

    if (!games.loaded && !games.loading) {
      this.props.dispatch({
        type: GAME_LIST,
        companyKey: companies.currentCompanyKey
      });
    }

    window.scrollTo(0, 0);
  }


  render() {
    const {t, gameCategories, games} = this.props;

    if (!games.loaded || !gameCategories.loaded) {
      return <Loading/>
    }

    return (
      <Fragment>
        {Object.keys(gameCategories.data).map((key, index) => {
          const category = gameCategories.data[key];
          return (
            <Fragment key={index}>
              <h1>{category.title}</h1>
              <GameList
                gameKeys={category.gameKeys}
                games={games}
                t={t}
              />
            </Fragment>
          );
        })}
      </Fragment>
    );
  }
}


const mapStateToProps = ({companies, gameCategories, games}) => ({companies, gameCategories, games});
export default connect(mapStateToProps)(withTranslation()(Games));