import {call, put, take} from 'redux-saga/effects';

import apiClient from "../api-client";
import {
  COMPANY_CONTACT_INVITE_GET,
  COMPANY_CONTACT_INVITE_GET_FAIL,
  COMPANY_CONTACT_INVITE_GET_OK
} from "../actions";


function* getCompanyContactInviteWorker({key}) {
  try {
    const response = yield call(apiClient.get, `company-contact-invites/${key}/`);
    yield put({
      type: COMPANY_CONTACT_INVITE_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: COMPANY_CONTACT_INVITE_GET_FAIL, payload: error.response.data
    });
  }
}

export function* getCompanyContactInvite() {
  while (true) {
    const request = yield take(COMPANY_CONTACT_INVITE_GET);
    yield call(getCompanyContactInviteWorker, request);
  }
}
