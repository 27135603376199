export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGIN_OK = "AUTH_LOGIN_OK";
export const AUTH_LOGIN_FAIL = "AUTH_LOGIN_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_LOGOUT_OK = "AUTH_LOGOUT_OK";

export const ACCOUNT_SEND_PASSWORD_RESET = "ACCOUNT_SEND_PASSWORD_RESET";
export const ACCOUNT_SEND_PASSWORD_RESET_OK = "ACCOUNT_SEND_PASSWORD_RESET_OK";
export const ACCOUNT_SEND_PASSWORD_RESET_FAIL = "ACCOUNT_SEND_PASSWORD_RESET_FAIL";

export const ACCOUNT_PASSWORD_RESET = "ACCOUNT_PASSWORD_RESET";
export const ACCOUNT_PASSWORD_RESET_OK = "ACCOUNT_PASSWORD_RESET_OK";
export const ACCOUNT_PASSWORD_RESET_FAIL = "ACCOUNT_PASSWORD_RESET_FAIL";

export const ACCOUNT_PASSWORD_PUT = "ACCOUNT_PASSWORD_PUT";
export const ACCOUNT_PASSWORD_PUT_OK = "ACCOUNT_PASSWORD_PUT_OK";
export const ACCOUNT_PASSWORD_PUT_FAIL = "ACCOUNT_PASSWORD_PUT_FAIL";

export const ACCOUNT_PASSWORD_FORGOT = "ACCOUNT_PASSWORD_FORGOT";
export const ACCOUNT_PASSWORD_FORGOT_OK = "ACCOUNT_PASSWORD_FORGOT_OK";
export const ACCOUNT_PASSWORD_FORGOT_FAIL = "ACCOUNT_PASSWORD_FORGOT_FAIL";

export const ACCOUNT_REGISTER = "ACCOUNT_REGISTER";
export const ACCOUNT_REGISTER_OK = "ACCOUNT_REGISTER_OK";
export const ACCOUNT_REGISTER_FAIL = "ACCOUNT_REGISTER_FAIL";

export const ACCOUNT_GET = 'ACCOUNT_GET';
export const ACCOUNT_GET_OK = 'ACCOUNT_GET_OK';
export const ACCOUNT_GET_FAIL = 'ACCOUNT_GET_FAIL';

export const COMPANY_LIST = 'COMPANY_LIST';
export const COMPANY_LIST_OK = 'COMPANY_LIST_OK';
export const COMPANY_LIST_FAIL = 'COMPANY_LIST_FAIL';

export const COMPANY_GET = 'COMPANY_GET';
export const COMPANY_GET_OK = 'COMPANY_GET_OK';
export const COMPANY_GET_FAIL = 'COMPANY_GET_FAIL';

export const COMPANY_TERMS_GET = 'COMPANY_TERMS_GET_FAIL';
export const COMPANY_TERMS_GET_OK = 'COMPANY_TERMS_GET_OK';
export const COMPANY_TERMS_GET_FAIL = 'COMPANY_TERMS_GET_FAIL';

export const COMPANY_TERMS_PUT = 'COMPANY_TERMS_PUT';
export const COMPANY_TERMS_PUT_OK = 'COMPANY_TERMS_PUT_OK';
export const COMPANY_TERMS_PUT_FAIL = 'COMPANY_TERMS_PUT_FAIL';

export const COMPANY_CONTACT_INVITE_GET = 'COMPANY_CONTACT_INVITE_GET';
export const COMPANY_CONTACT_INVITE_GET_OK = 'COMPANY_CONTACT_INVITE_GET_OK';
export const COMPANY_CONTACT_INVITE_GET_FAIL = 'COMPANY_CONTACT_INVITE_GET_FAIL';

export const COMPANY_DETAILS_PUT = 'COMPANY_DETAILS_PUT';
export const COMPANY_DETAILS_PUT_OK = 'COMPANY_DETAILS_PUT_OK';
export const COMPANY_DETAILS_PUT_FAIL = 'COMPANY_DETAILS_PUT_FAIL';

export const GAME_LIST = 'GAME_LIST';
export const GAME_LIST_OK = 'GAME_LIST_OK';
export const GAME_LIST_FAIL = 'GAME_LIST_FAIL';

export const GAME_CATEGORY_LIST = 'GAME_CATEGORY_LIST';
export const GAME_CATEGORY_LIST_OK = 'GAME_CATEGORY_LIST_OK';
export const GAME_CATEGORY_LIST_FAIL = 'GAME_CATEGORY_LIST_FAIL';

export const COMPANY_SET_CURRENT_KEY = 'COMPANY_SET_CURRENT_KEY';

export const DASHBOARD_TOTALS_GET = 'DASHBOARD_TOTALS_GET';
export const DASHBOARD_TOTALS_GET_OK = 'DASHBOARD_TOTALS_GET_OK';
export const DASHBOARD_TOTALS_GET_FAIL = 'DASHBOARD_TOTALS_GET_FAIL';

export const CHART_DATA_IMPRESSIONS_BY_DATE_GET = 'CHART_DATA_IMPRESSIONS_BY_DATE_GET';
export const CHART_DATA_IMPRESSIONS_BY_DATE_GET_OK = 'CHART_DATA_IMPRESSIONS_BY_DATE_GET_OK';
export const CHART_DATA_IMPRESSIONS_BY_DATE_GET_FAIL = 'CHART_DATA_IMPRESSIONS_BY_DATE_GET_FAIL';

export const CHART_DATA_REVENUE_BY_DATE_GET = 'CHART_DATA_REVENUE_BY_DATE_GET';
export const CHART_DATA_REVENUE_BY_DATE_GET_OK = 'CHART_DATA_REVENUE_BY_DATE_GET_OK';
export const CHART_DATA_REVENUE_BY_DATE_GET_FAIL = 'CHART_DATA_REVENUE_BY_DATE_GET_FAIL';

export const CHART_DATA_ECPM_BY_DATE_GET = 'CHART_DATA_ECPM_BY_DATE_GET';
export const CHART_DATA_ECPM_BY_DATE_GET_OK = 'CHART_DATA_ECPM_BY_DATE_GET_OK';
export const CHART_DATA_ECPM_BY_DATE_GET_FAIL = 'CHART_DATA_ECPM_BY_DATE_GET_FAIL';


export const CHART_DATA_IMPRESSIONS_BY_GAME_GET = 'CHART_DATA_IMPRESSIONS_BY_GAME_GET';
export const CHART_DATA_IMPRESSIONS_BY_GAME_GET_OK = 'CHART_DATA_IMPRESSIONS_BY_GAME_GET_OK';
export const CHART_DATA_IMPRESSIONS_BY_GAME_GET_FAIL = 'CHART_DATA_IMPRESSIONS_BY_GAME_GET_FAIL';

export const CHART_DATA_REVENUE_BY_GAME_GET = 'CHART_DATA_REVENUE_BY_GAME_GET';
export const CHART_DATA_REVENUE_BY_GAME_GET_OK = 'CHART_DATA_REVENUE_BY_GAME_GET_OK';
export const CHART_DATA_REVENUE_BY_GAME_GET_FAIL = 'CHART_DATA_REVENUE_BY_GAME_GET_FAIL';

export const CHART_DATA_ECPM_BY_GAME_GET = 'CHART_DATA_ECPM_BY_GAME_GET';
export const CHART_DATA_ECPM_BY_GAME_GET_OK = 'CHART_DATA_ECPM_BY_GAME_GET_OK';
export const CHART_DATA_ECPM_BY_GAME_GET_FAIL = 'CHART_DATA_ECPM_BY_GAME_GET_FAIL';


export const REPORT_BY_DATE_LIST = 'REPORT_BY_DATE_LIST';
export const REPORT_BY_DATE_LIST_OK = 'REPORT_BY_DATE_LIST_OK';
export const REPORT_BY_DATE_LIST_FAIL = 'REPORT_BY_DATE_LIST_FAIL';

export const REPORT_BY_GAME_LIST = 'REPORT_BY_GAME_LIST';
export const REPORT_BY_GAME_LIST_OK = 'REPORT_BY_GAME_LIST_OK';
export const REPORT_BY_GAME_LIST_FAIL = 'REPORT_BY_GAME_LIST_FAIL';

export const INVOICE_LIST = 'INVOICE_LIST';
export const INVOICE_LIST_OK = 'INVOICE_LIST_OK';
export const INVOICE_LIST_FAIL = 'INVOICE_LIST_FAIL';

