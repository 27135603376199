import React, {Fragment, Component} from 'react';
import i18n from "../../i18n";
import {withCookies} from "react-cookie";
import {withTranslation} from "react-i18next";
import {Button, Container, Header} from "semantic-ui-react";


class AccountChangeLanguage extends Component {

  render() {
    const {t, cookies} = this.props;

    const toggle = (lng) => {
      cookies.set('current_language', lng, {path: '/'});
      i18n.changeLanguage(lng)
    };


    return (
      <Container text>
        <Header as='h2'>
          {t('account.changeLanguage.title')}
        </Header>
        <p>{t('account.changeLanguage.text')}</p>
        {i18n.language !== 'en' &&
        <Fragment>
          <Button positive onClick={() => toggle('en')}>
            {t('main.languages.english.abbreviation')}
          </Button>

          <Button disabled>
            {t('main.languages.japanese.abbreviation')}
          </Button>
        </Fragment>
        }
        {i18n.language !== 'ja' &&
        <Fragment>
          <Button disabled>
            {t('main.languages.english.abbreviation')}
          </Button>
          <Button positive onClick={() => toggle('ja')}>
            {t('main.languages.japanese.abbreviation')}
          </Button>
        </Fragment>

        }
      </Container>
    );
  }
}

export default withTranslation()(withCookies(AccountChangeLanguage));

