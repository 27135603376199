import React, {Component} from 'react';
import {Container, Header} from "semantic-ui-react";
import Breadcrumb from "./breadcrumb";
import {renderContent} from "../helpers/content";
import connect from "react-redux/es/connect/connect";
import {withTranslation} from 'react-i18next';


class Route404 extends Component {

  render() {
    const {t, location} = this.props;

    return (
      <Container text className='page'>
        <Breadcrumb active={t('content.404.title')}/>

        <Header as='h1'>404</Header>
        <p>{renderContent(t('content.404.text'))}</p>
      </Container>

    );
  }
}

export default connect()(withTranslation()(Route404));