import React from "react";
import Main from "./main";
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import PrivateRoute from "../helpers/private-route";
import Login from "./auth/login";
import registrationForm from "./account/registration";
import forgotPasswordForm from "./account/forgot-password";
import resetPasswordForm from "./account/reset-password";
import Route404 from "../ui/route-404";


class App extends React.Component {

  previousLocation = this.props.location;

 componentDidUpdate(prevProps) {
    let {location} = prevProps;
    if (this.props.history.action !== "POP" && (!location.state || !location.state.modal)) {
      this.previousLocation = location;
    }
 }

  render() {

    let { location } = this.props;
    let isModal = !!(location.state && location.state.modal && this.previousLocation !== location);

    return (
      <Switch location={isModal ? this.previousLocation : location}>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/forgot-password" component={forgotPasswordForm}/>
        <Route exact path="/password/reset/:uid/:token" component={resetPasswordForm}/>
        <Route exact path="/invite/:key" component={registrationForm}/>
        <PrivateRoute path="/" component={Main}/>
        <Route component={Route404}/>
      </Switch>
    )
  }
}

const mapStateToProps = ({auth}) => ({
  auth
});
export default withRouter(connect(mapStateToProps)(App));