import React, {Component, Fragment} from 'react'
import {Container, Icon, Table} from "semantic-ui-react";
import _ from 'lodash';
import {CSVLink} from 'react-csv';
import {ECPM_DIVIDER, REVENUE_DIVIDER} from "../../settings";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {REPORT_BY_DATE_LIST} from "../../actions";
import Loading from "../../ui/loading";
import ReportRow from "./ui/report-row";
import ReportSum from "./ui/report-sum";


class ReportsByDate extends Component {

  constructor(props) {
    super(props);

    this.state = {
      column: 'date',
      direction: 'ascending',
      data: null
    };
  }

  componentDidMount() {
    const {companies: {currentCompanyKey}, reportsByDate, startDate, endDate, dispatch} = this.props;
    if (!reportsByDate.loaded) {
      dispatch({
        type: REPORT_BY_DATE_LIST,
        currentCompanyKey, startDate, endDate
      });
    } else {
      if (reportsByDate.data.dates !== this.state.data) {
        this.setState({data: reportsByDate.data.dates});
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {companies: {currentCompanyKey}, startDate, endDate, dispatch} = this.props;
    const {startDate: prevStartDate, endDate: prevEndDate} = prevProps;
    const {reportsByDate} = this.props;

    if (reportsByDate.loaded && reportsByDate.data.dates !== this.state.data) {
      this.setState({data: reportsByDate.data.dates});
    }

    if (startDate !== prevStartDate || endDate !== prevEndDate) {
      dispatch({
        type: REPORT_BY_DATE_LIST,
        currentCompanyKey, startDate, endDate
      });
    }
  }

  handleSort = (clickedColumn) => () => {
    const {column, direction, data} = this.state;
    if (column !== clickedColumn) {

      this.setState({
        column: clickedColumn,
        data: _.sortBy(data, [clickedColumn]),
        direction: 'ascending',
      });
      return
    }

    this.setState({
      data: data.reverse(),
      direction: direction === 'ascending' ? 'descending' : 'ascending',
    })
  };

  render() {
    const {t, reportsByDate, startDate, endDate} = this.props;
    const {column, direction, data} = this.state;

    if (!reportsByDate.loaded || data === null) {
      return <Loading/>;
    }
    let rows = [];
    let csvData = [];

    let totalImpressions = 0;
    let totalNetRevenue = 0;
    let totalAverageECPM = 0;
    let totalAverageECPMCount = 0;

    Object.keys(data).map((key, index) => {
      const report = data[key];
      totalImpressions += report.impressions ? report.impressions : 0;
      totalNetRevenue += report.revenue ? report.revenue : 0;
      totalAverageECPM += report.ecpm ? report.ecpm : 0;
      totalAverageECPMCount += report.ecpm ? 1 : 0;

      csvData.push({
        date: report.date,
        impressions: report.impressions,
        revenue: report.revenue / REVENUE_DIVIDER,
        ecpm: report.ecpm / ECPM_DIVIDER
      });

      rows.push(<ReportRow key={index} report={report}/>);
    });
    totalAverageECPM /= totalAverageECPMCount;


    return (
      <Fragment>
        <Container align={'right'}>
          {reportsByDate.loaded &&
          <CSVLink
            className='download-csv'
            headers={[
              {label: t('main.date'), key: "date"},
              {label: t('charts.impressions.title'), key: "impressions"},
              {label: t('charts.netRevenue.title'), key: "revenue"},
              {label: t('charts.averageECPM.title'), key: "ecpm"}
            ]}
            data={csvData}
            filename={`${startDate} - ${endDate}.csv`}>
            <Icon name='download'/>{t('main.downloadCSV')}
          </CSVLink>
          }
        </Container>
        <Table sortable celled striped fixed>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                sorted={column === 'date' ? direction : null}
                onClick={this.handleSort('date')}>{t('main.date')}</Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'impressions' ? direction : null}
                onClick={this.handleSort('impressions')}>{t('charts.impressions.title')}</Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'revenue' ? direction : null}
                onClick={this.handleSort('revenue')}>{t('charts.netRevenue.title')}</Table.HeaderCell>
              <Table.HeaderCell
                sorted={column === 'ecpm' ? direction : null}
                onClick={this.handleSort('ecpm')}>{t('charts.averageECPM.title')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {rows}
          </Table.Body>
          <Table.Footer>
            {<ReportSum
              t={t}
              totalImpressions={totalImpressions}
              totalNetRevenue={totalNetRevenue}
              totalAverageECPM={totalAverageECPM}
            />}
          </Table.Footer>
        </Table>
      </Fragment>
    );
  }
}

const mapStateToProps = ({companies, reportsByDate}) => ({
  companies,
  reportsByDate
});
export default connect(mapStateToProps)(withTranslation()(ReportsByDate));