import React, {Component} from 'react';
import {connect} from "react-redux";
import Chart from "react-apexcharts";
import Loading from "../../ui/loading";
import {chartByDateOptions} from "./_chart-options";
import {ECPM_DIVIDER} from "../../settings";
import {withTranslation} from "react-i18next";

class ChartByDateECPM extends Component {

  render() {
    const {t, dataByDateECPM: {loaded, data}} = this.props;
    if (!loaded) {
      return <Loading/>
    }

    const options = {
      ...chartByDateOptions,
      xaxis: data.xaxis,
      yaxis: {
        labels: {
          formatter: function (value) {
            return `€ ${(Math.round(value / ECPM_DIVIDER * 100) / 100).toFixed(2)}`
          }
        },
      },
      dataLabels: {
        ...chartByDateOptions.dataLabels,
        formatter: (value, opts) => {
          return `€ ${(Math.round(value / ECPM_DIVIDER * 100) / 100).toFixed(2)}`
        },
      },
      title: {
        text: t('charts.averageECPM.perDateTitle'),
        align: 'center', style: {fontSize: '16px'}
      }
    };
    return (
      <Chart
        options={options}
        series={data.series}
        type="line"
        height="450px" width="100%"/>
    );
  }
}

const mapStateToProps = ({dataByDateECPM}) => ({
  dataByDateECPM
});
export default connect(mapStateToProps)(withTranslation()(ChartByDateECPM));