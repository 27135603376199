import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {ACCOUNT_REGISTER, COMPANY_CONTACT_INVITE_GET} from "../../actions";
import {Redirect, withRouter} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Image
} from "semantic-ui-react";
import {required} from '../../helpers/validation';
import ErrorMessage from "../../ui/error-message";
import {InputField} from "../../helpers/forms";
import CoolGamesLogo from '../../static/img/logo-coolgames.png';
import Loading from "../../ui/loading";


class Registration extends Component {

  constructor(props) {
    super(props);
    this.handleRegister = this.handleRegister.bind(this);
  }


  componentDidMount() {
    const {companyContactInvite, match} = this.props;
    if (!companyContactInvite.loaded && !companyContactInvite.loading) {
      this.props.dispatch({
        type: COMPANY_CONTACT_INVITE_GET,
        key: match.params.key
      });
    }
  }

  handleRegister(values) {
    const {match, history} = this.props;
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: ACCOUNT_REGISTER,
        data: {...values, inviteKey: match.params.key},
        resolve,
        reject
      });
    }).then(() => {
      history.push('/');
    }).catch((error) => {
      throw new SubmissionError({...error.errors});
    });
  }

  render() {
    const {t, companyContactInvite} = this.props;
    const {auth, handleSubmit, pristine, submitting, error} = this.props;

    const {from} = this.props.location.state || {from: {pathname: '/'}};
    if (auth.authenticated) {
      return (
        <Redirect to={from}/>
      );
    }

    if (companyContactInvite.error) {
      return (<Redirect to={'/'}/>);
    }

    if (!companyContactInvite.loaded) {
      return <Loading/>
    }

    return (
      <Container text className='page'>
        <Image src={CoolGamesLogo} size='medium' centered/><br/>
        <Header as='h2'>
          {t('registration.title')}
        </Header>
        <p>You have been invited to create your CoolGames Publishing
          account. <br/>
          Please enter your account details to get started!
        </p>
        <Divider/>
        <Form onSubmit={handleSubmit(this.handleRegister)} noValidate>
          <Field
            disabled={true}
            name='email'
            type='text'
            component={InputField}
            label={t('registration.form.email')}
            placeholder={companyContactInvite.data.email}/>

          <Field
            autoFocus
            required={true}
            focus={true}
            name='username'
            type='text'
            component={InputField}
            validate={required}
            label={t('registration.form.username')}
            placeholder='Username'/>

          <Field
            required={true}
            name='password1'
            type='password'
            component={InputField}
            validate={required}
            label={t('registration.form.password')}
            placeholder='Password'/>

          <Field
            required={true}
            name='password2'
            type='password'
            component={InputField}
            validate={required}
            label={t('registration.form.repeatPassword')}
            placeholder='Password'/>

          {error &&
          <ErrorMessage message={error}/>
          }
          <Button
            positive
            floated='right'
            loading={submitting}
            disabled={pristine || submitting}>
            {t('registration.buttons.register')}
          </Button>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = ({auth, companyContactInvite}) => ({
  auth,
  companyContactInvite
});
const registrationForm = reduxForm({
  form: 'registerForm',
  touchOnBlur: false
})(Registration);
export default connect(mapStateToProps)(withTranslation()(withRouter(registrationForm)));




