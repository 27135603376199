import React, {Component} from 'react';
import NumberFormat from 'react-number-format';
import {REVENUE_DIVIDER} from "../settings";

export default class Euro extends Component {

  render() {
    const {value, decimalScale=2, divider = REVENUE_DIVIDER} = this.props;

    return (
      <NumberFormat
        value={value / divider}
        thousandSeparator={','}
        displayType={'text'}
        decimalScale={decimalScale}
        prefix={'€'}/>
    );
  }
}
