import React, {Component} from 'react';
import {connect} from "react-redux";
import Chart from "react-apexcharts";
import Loading from "../../ui/loading";
import {chartByGameOptions} from "./_chart-options";
import {REVENUE_DIVIDER} from "../../settings";
import {withTranslation} from "react-i18next";

class ChartByGameRevenue extends Component {

  render() {
    const {t, dataByGameRevenue: {loaded, data}} = this.props;
    if (!loaded) {
      return <Loading/>
    }

    return (
      <Chart
        options={{
          ...chartByGameOptions,
          xaxis: data.xaxis,
          yaxis: {
            labels: {
              formatter: function (value) {
                return `€ ${(Math.round(value / REVENUE_DIVIDER * 100) / 100).toFixed(2)}`
              }
            },
          },
          dataLabels: {
            ...chartByGameOptions.dataLabels,
            formatter: (value, opts) => {
              return `€ ${(Math.round(value / REVENUE_DIVIDER * 100) / 100).toFixed(2)}`
            },
          },
          title: {
            text: t('charts.netRevenue.perGameTitle'),
            align: 'center', style: {fontSize: '16px'}
          }
        }}
        series={[data.series]}
        type="bar"
        height="450px" width="100%"/>
    );

  }
}

const mapStateToProps = ({dataByGameRevenue}) => ({
  dataByGameRevenue
});
export default connect(mapStateToProps)(withTranslation()(ChartByGameRevenue));