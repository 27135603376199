import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Breadcrumb as SUIBreadcrumb} from "semantic-ui-react";
import {NavLink, withRouter} from "react-router-dom";
import {flow} from "lodash";


class Breadcrumb extends Component {

  render() {
    const {t, history, active} = this.props;
    let {home=null} = this.props;

    home = home ? home : t('buttons.home');

    return (
      <SUIBreadcrumb>
        <SUIBreadcrumb.Section
          as={NavLink}
          to='/'
          onClick={() => history.push('/')}>
          {home}</SUIBreadcrumb.Section>
        <SUIBreadcrumb.Divider icon='right angle'/>
        <SUIBreadcrumb.Section active>{active}</SUIBreadcrumb.Section>
      </SUIBreadcrumb>
    );
  }
}

export default flow(
  withRouter,
  withTranslation()
)(Breadcrumb);