import {call, put, take} from 'redux-saga/effects';

import apiClient from "../api-client";
import {
  CHART_DATA_ECPM_BY_DATE_GET,
  CHART_DATA_ECPM_BY_DATE_GET_FAIL,
  CHART_DATA_ECPM_BY_DATE_GET_OK,
  CHART_DATA_ECPM_BY_GAME_GET,
  CHART_DATA_ECPM_BY_GAME_GET_FAIL,
  CHART_DATA_ECPM_BY_GAME_GET_OK,
  CHART_DATA_IMPRESSIONS_BY_DATE_GET,
  CHART_DATA_IMPRESSIONS_BY_DATE_GET_FAIL,
  CHART_DATA_IMPRESSIONS_BY_DATE_GET_OK,
  CHART_DATA_IMPRESSIONS_BY_GAME_GET,
  CHART_DATA_IMPRESSIONS_BY_GAME_GET_FAIL,
  CHART_DATA_IMPRESSIONS_BY_GAME_GET_OK,
  CHART_DATA_REVENUE_BY_DATE_GET,
  CHART_DATA_REVENUE_BY_DATE_GET_FAIL,
  CHART_DATA_REVENUE_BY_DATE_GET_OK,
  CHART_DATA_REVENUE_BY_GAME_GET,
  CHART_DATA_REVENUE_BY_GAME_GET_FAIL,
  CHART_DATA_REVENUE_BY_GAME_GET_OK,
  DASHBOARD_TOTALS_GET,
  DASHBOARD_TOTALS_GET_FAIL,
  DASHBOARD_TOTALS_GET_OK,
  REPORT_BY_DATE_LIST,
  REPORT_BY_DATE_LIST_FAIL,
  REPORT_BY_DATE_LIST_OK,
  REPORT_BY_GAME_LIST, REPORT_BY_GAME_LIST_FAIL,
  REPORT_BY_GAME_LIST_OK
} from "../actions";


function* getDashboardTotalsWorker({currentCompanyKey, startDate, endDate}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${currentCompanyKey}/data/totals/?s=${startDate}&e=${endDate}`
    );
    yield put({
      type: DASHBOARD_TOTALS_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: DASHBOARD_TOTALS_GET_FAIL, payload: error.response.data
    });
  }
}

export function* getDashboardTotals() {
  while (true) {
    const request = yield take(DASHBOARD_TOTALS_GET);
    yield call(getDashboardTotalsWorker, request);
  }
}


function* getChartDataImpressionsByDateWorker({currentCompanyKey, startDate, endDate}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${currentCompanyKey}/data/impressions-by-date/?s=${startDate}&e=${endDate}`
    );
    yield put({
      type: CHART_DATA_IMPRESSIONS_BY_DATE_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: CHART_DATA_IMPRESSIONS_BY_DATE_GET_FAIL,
      payload: error.response.data
    });
  }
}

export function* getChartDataImpressionsByDate() {
  while (true) {
    const request = yield take(CHART_DATA_IMPRESSIONS_BY_DATE_GET);

    yield call(getChartDataImpressionsByDateWorker, request);
  }
}


function* getChartDataImpressionsByGameWorker({currentCompanyKey, startDate, endDate}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${currentCompanyKey}/data/impressions-by-game/?s=${startDate}&e=${endDate}`
    );
    yield put({
      type: CHART_DATA_IMPRESSIONS_BY_GAME_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: CHART_DATA_IMPRESSIONS_BY_GAME_GET_FAIL,
      payload: error.response.data
    });
  }
}

export function* getChartDataImpressionsByGame() {
  while (true) {
    const request = yield take(CHART_DATA_IMPRESSIONS_BY_GAME_GET);
    yield call(getChartDataImpressionsByGameWorker, request);
  }
}


function* getChartDataRevenueByDateWorker({currentCompanyKey, startDate, endDate}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${currentCompanyKey}/data/revenue-by-date/?s=${startDate}&e=${endDate}`
    );
    yield put({
      type: CHART_DATA_REVENUE_BY_DATE_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: CHART_DATA_REVENUE_BY_DATE_GET_FAIL, payload: error.response.data
    });
  }
}

export function* getChartDataRevenueByDate() {
  while (true) {
    const request = yield take(CHART_DATA_REVENUE_BY_DATE_GET);
    yield call(getChartDataRevenueByDateWorker, request);
  }
}


function* getChartDataRevenueByGameWorker({currentCompanyKey, startDate, endDate}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${currentCompanyKey}/data/revenue-by-game/?s=${startDate}&e=${endDate}`
    );
    yield put({
      type: CHART_DATA_REVENUE_BY_GAME_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: CHART_DATA_REVENUE_BY_GAME_GET_FAIL, payload: error.response.data
    });
  }
}

export function* getChartDataRevenueByGame() {
  while (true) {
    const request = yield take(CHART_DATA_REVENUE_BY_GAME_GET);
    yield call(getChartDataRevenueByGameWorker, request);
  }
}


function* getChartDataECPMByDateWorker({currentCompanyKey, startDate, endDate}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${currentCompanyKey}/data/ecpm-by-date/?s=${startDate}&e=${endDate}`
    );
    yield put({
      type: CHART_DATA_ECPM_BY_DATE_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: CHART_DATA_ECPM_BY_DATE_GET_FAIL, payload: error.response.data
    });
  }
}

export function* getChartDataECPMByDate() {
  while (true) {
    const request = yield take(CHART_DATA_ECPM_BY_DATE_GET);
    yield call(getChartDataECPMByDateWorker, request);
  }
}

function* getChartDataECPMByGameWorker({currentCompanyKey, startDate, endDate}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${currentCompanyKey}/data/ecpm-by-game/?s=${startDate}&e=${endDate}`
    );
    yield put({
      type: CHART_DATA_ECPM_BY_GAME_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: CHART_DATA_ECPM_BY_GAME_GET_FAIL, payload: error.response.data
    });
  }
}

export function* getChartDataECPMByGame() {
  while (true) {
    const request = yield take(CHART_DATA_ECPM_BY_GAME_GET);
    yield call(getChartDataECPMByGameWorker, request);
  }
}


function* getReportByDateListWorker({currentCompanyKey, startDate, endDate}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${currentCompanyKey}/data/reports-by-date/?s=${startDate}&e=${endDate}`
    );
    yield put({
      type: REPORT_BY_DATE_LIST_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: REPORT_BY_DATE_LIST_FAIL, payload: error.response.data
    });
  }
}

export function* getReportByDateList() {
  while (true) {
    const request = yield take(REPORT_BY_DATE_LIST);
    yield call(getReportByDateListWorker, request);
  }
}


function* getReportByGameListWorker({currentCompanyKey, startDate, endDate}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${currentCompanyKey}/data/reports-by-game/?s=${startDate}&e=${endDate}`
    );
    yield put({
      type: REPORT_BY_GAME_LIST_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: REPORT_BY_GAME_LIST_FAIL, payload: error.response.data
    });
  }
}

export function* getReportByGameList() {
  while (true) {
    const request = yield take(REPORT_BY_GAME_LIST);
    yield call(getReportByGameListWorker, request);
  }
}




