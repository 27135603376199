import {call, put, take} from 'redux-saga/effects';

import apiClient from "../api-client";
import {
  GAME_CATEGORY_LIST,
  GAME_CATEGORY_LIST_FAIL,
  GAME_CATEGORY_LIST_OK,
  GAME_LIST,
  GAME_LIST_FAIL,
  GAME_LIST_OK
} from "../actions";


function* getGameListWorker({companyKey}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${companyKey}/games/`
    );
    yield put({
      type: GAME_LIST_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: GAME_LIST_FAIL, payload: error.response.data
    });
  }
}

export function* getGameList() {
  while (true) {
    const request = yield take(GAME_LIST);
    yield call(getGameListWorker, request);
  }
}


function* getGameCategoryListWorker(request) {
  try {
    const response = yield call(apiClient.get, `game-categories/`);
    yield put({
      type: GAME_CATEGORY_LIST_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: GAME_CATEGORY_LIST_FAIL, payload: error.response.data
    });
  }
}

export function* getGameCategoryList() {
  while (true) {
    const request = yield take(GAME_CATEGORY_LIST);
    yield call(getGameCategoryListWorker, request);
  }
}

