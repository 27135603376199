import {combineReducers} from "redux";
import {reducer as formReducer} from 'redux-form';
import {reducer as toastrReducer} from 'react-redux-toastr';
import {AUTH_LOGOUT} from "../actions";

import auth from "./auth";
import account from "./account";
import companies from "./companies";
import company from "./company";
import companyTerms from "./company-terms";
import companyContactInvite from "./company-contact-invite";
import games from "./games";
import gameCategories from "./game-categories";
import dashboard from "./dashboard";
import dataByDateImpressions from "./data-by-date-impressions";
import dataByGameImpressions from "./data-by-game-impressions";
import dataByDateRevenue from "./data-by-date-revenue";
import dataByGameRevenue from "./data-by-game-revenue";
import dataByDateECPM from "./data-by-date-ecpm";
import dataByGameECPM from "./data-by-game-ecpm";
import reportsByDate from "./reports-by-date";
import reportsByGame from "./reports-by-game";
import invoices from "./invoices";


const rootReducer = combineReducers({
  auth,
  account,
  companies,
  company,
  companyTerms,
  companyContactInvite,
  dashboard,
  dataByDateImpressions,
  dataByDateRevenue,
  dataByDateECPM,
  dataByGameImpressions,
  dataByGameRevenue,
  dataByGameECPM,
  reportsByDate,
  reportsByGame,
  invoices,

  games,
  gameCategories,


  form: formReducer,
  toastr: toastrReducer
});


export default (state, action) => {
  if (action.type === AUTH_LOGOUT) {
    state = undefined;
  }
  return rootReducer(state, action)
};

