import React, {Component} from 'react'
import NumberFormat from 'react-number-format';

export default class Impressions extends Component {
  render() {
    const {amount} = this.props;
    return amount ? <NumberFormat
      value={amount}
      thousandSeparator={','}
      displayType={'text'}
    /> : '-';
  }
}
