import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux';
import {COMPANY_GET, COMPANY_TERMS_GET, COMPANY_TERMS_PUT} from "../../actions";
import Loading from "../../ui/loading";
import {withTranslation} from "react-i18next";
import {renderContent} from '../../helpers/content';
import {Button, Container, Form, Header} from "semantic-ui-react";
import {Field, reduxForm, SubmissionError} from "redux-form";
import {CheckboxField} from "../../helpers/forms";

class TermsAndConditions extends Component {

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const {company, companyTerms} = this.props;

    if (!company.loaded && !company.loading) {
      this.props.dispatch({type: COMPANY_GET});
    }
    if (!companyTerms.loaded && !companyTerms.loading) {
      this.props.dispatch({
        type: COMPANY_TERMS_GET,
        companyKey: company.data.key
      });
    }
  }

  submit(values) {
    const {t} = this.props;

    if (!values.agree) {
      throw new SubmissionError({
        agree: t('errors.required')
      })
    }

    const {company} = this.props;
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: COMPANY_TERMS_PUT,
        companyKey: company.data.key,
        resolve,
        reject
      });
    }).then(() => {

    }).catch((error) => {
      throw new SubmissionError({...error.errors});
    });
  }

  render() {
    const {t, handleSubmit, companyTerms, submitting, readOnly = false} = this.props;
    const {company} = this.props;


    if (!company.loaded || !companyTerms.loaded) {
      return <Loading/>
    }

    const termsText = company.data.terms;

    let checkBoxLabel = t('terms.form.agree');
    if (readOnly) {
      checkBoxLabel = t('terms.form.hasAgreed');
    }

    return (
      <Container text className='main-content'>
        <Header as='h1'>{t('terms.title')}</Header>
        {renderContent(termsText)}
        <br/><br/>
        <Form id='termsForm' onSubmit={handleSubmit(this.submit)} noValidate>
          {readOnly &&
          <Field
            name='agree'
            component={CheckboxField}
            label={checkBoxLabel}
            checked={true}
            disabled={true}
          />
          }
          {!readOnly &&
          <Fragment>
            <Field
              name='agree'
              component={CheckboxField}
              label={checkBoxLabel}
            />
            <Button positive loading={submitting}>
              {t('terms.buttons.continue')}
            </Button>
          </Fragment>
          }
        </Form>

      </Container>
    );
  }
}

const termsAndConditionsForm = reduxForm({
  form: 'termsAndConditionsForm',
  touchOnBlur: false
})(TermsAndConditions);

const mapStateToProps = ({company, companyTerms}) => ({company, companyTerms});
export default connect(mapStateToProps)(withTranslation()(termsAndConditionsForm));