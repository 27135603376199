import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Container, Divider, Header} from "semantic-ui-react";
import AccountChangePassword from "./change-password";
import AccountChangeLanguage from "./change-language";

class AccountSettings extends Component {


  render() {
    const {t} = this.props;

    return (
      <Container text className='main-content'>
        <Header as='h1'>
          {t('account.settings.title')}
        </Header>
        <AccountChangeLanguage/>
        <Divider/>
        <AccountChangePassword/>
      </Container>
    );
  }
}


export default (withTranslation()(AccountSettings));

