import {
  COMPANY_GET,
  INVOICE_LIST,
  INVOICE_LIST_FAIL,
  INVOICE_LIST_OK
} from "../actions";


const initialState = {
  data: null,

  loading: false,
  loaded: false,

  error: false,
  errorMessage: null,
};


export default function reducer(state = initialState, action) {
  switch (action.type) {

    case COMPANY_GET: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
        data: null
      };
    }

    case INVOICE_LIST: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }

    case INVOICE_LIST_OK: {
      let data = {};
      if(action.payload) {
        action.payload.forEach(g => {
          data[g.key] = g;
        });
      }
      return {
        ...state,
        loading: false,
        loaded: true,
        data
      };
    }

    case INVOICE_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        errorMessage: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

