import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {InputField} from "../../helpers/forms";
import {ACCOUNT_PASSWORD_PUT} from "../../actions";
import {Button, Container, Divider, Form, Header} from "semantic-ui-react";
import {required} from "../../helpers/validation";
import {toastr} from 'react-redux-toastr';


class AccountChangePassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      success: false,
      invalidToken: false
    };
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    const {t} = this.props;
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: ACCOUNT_PASSWORD_PUT,
        data: {...values},
        resolve, reject
      });
    }).then(() => {
      toastr.success(t('notifications.saved.title'), t('notifications.saved.text'));
    }).catch((error) => {
      toastr.error(t('notifications.errors.title'), t('notifications.errors.text'));
      throw new SubmissionError({...error.errors,});
    })
  };

  render() {
    const {t, handleSubmit, pristine, submitting} = this.props;
    const {success} = this.state;


    if (success) {
      return (
        <Container text className='page'>
          <Header as='h2'>
            {t('account.changePassword.passwordChangedTitle')}
          </Header>
          <p>{t('account.changePassword.passwordChangedText')}</p>
          <Link to='/'>
            {t('buttons.back')}
          </Link>
        </Container>
      )
    }

    return (
      <Container text>
        <Header as='h2'>
          {t('account.changePassword.title')}
        </Header>
        <p>{t('account.changePassword.text')}</p>
        <Divider/>
        <Form onSubmit={handleSubmit(this.submit)} noValidate>

          <Field
            required={true}
            name='oldPassword'
            type='password'
            component={InputField}
            validate={required}
            label={t('account.changePassword.form.currentPassword')}
            placeholder={t('account.changePassword.form.password')}
          />
          <Field
            required={true}
            name='newPassword1'
            type='password'
            component={InputField}
            validate={required}
            label={t('account.changePassword.form.newPassword')}
            placeholder={t('account.changePassword.form.password')}
          />

          <Field
            required={true}
            name='newPassword2'
            type='password'
            component={InputField}
            validate={required}
            label={t('account.changePassword.form.repeatNewPassword')}
            placeholder={t('account.changePassword.form.password')}
          />

          <Button
            positive
            floated='right'
            loading={submitting}
            disabled={pristine || submitting}>
            {t('account.changePassword.buttons.changePassword')}
          </Button>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = ({auth, account}) => ({auth, account});
const accountChangePasswordForm = reduxForm({form: 'accountChangePasswordForm'})(AccountChangePassword);
export default connect(mapStateToProps)(withTranslation()(accountChangePasswordForm));

