import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {
  COMPANY_DETAILS_PUT,
  COMPANY_GET,
  COMPANY_LIST,
  COMPANY_SET_CURRENT_KEY
} from "../../actions";
import {withTranslation} from 'react-i18next';
import {Button, Container, Divider, Form, Header} from "semantic-ui-react";
import {required, validateEmail} from '../../helpers/validation';
import ErrorMessage from "../../ui/error-message";
import {DropdownField, InputField} from "../../helpers/forms";
import {getNames} from "i18n-iso-countries";
import i18n from '../../i18n';
import {toastr} from 'react-redux-toastr';
import Loading from "../../ui/loading";


class CompanySettingsForm extends Component {

  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {companies, company} = this.props;

    if (company.loaded) {
      return;
    }

    if (!companies.loaded && !companies.loading) {
      if (!companies.error) {
        this.props.dispatch({type: COMPANY_LIST});
      }
    } else {
      let key;
      if (companies.currentCompanyKey) {
        key = companies.currentCompanyKey;
      } else {
        key = companies.data[0].key;
        this.props.dispatch({type: COMPANY_SET_CURRENT_KEY, payload: key});
      }
      this.props.dispatch({type: COMPANY_GET, key});
    }
  }

  submit(values) {
    const {t, companyKey} = this.props;
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: COMPANY_DETAILS_PUT,
        key: companyKey,
        data: values,
        resolve,
        reject
      });
    }).then(() => {
      toastr.success(t('notifications.saved.title'), t('notifications.saved.text'));
    }).catch((errors) => {
      toastr.error(t('notifications.errors.title'), t('notifications.errors.text'));
      throw new SubmissionError({...errors});
    });
  }


  render() {
    const {t, handleSubmit, pristine, submitting, error, company} = this.props;

    if (!company.loaded) {
      return <Loading/>;
    }

    let countries = [];
    let translatedCountries = getNames(i18n.language);
    for (const [key, label] of Object.entries(translatedCountries)) {
      countries.push(
        {key, text: t(label), value: key}
      )
    }

    return (
      <Container text className='main-content'>
        <Form onSubmit={handleSubmit(this.submit)} noValidate>
          <Header as='h3'>{t('company.details.title')}</Header>
          {!company.data.completed &&
          <p>{t('company.details.detailsMandatory')}</p>
          }
          <Divider/>
          <Header as='h4'>{t('company.details.contactTitle')}</Header>
          <Field
            autoFocus
            required={true}
            name='contactName'
            type='text'
            component={InputField}
            validate={required}
            label={t('company.details.form.contactName')}
            placeholder=''/>
          <Field
            autoFocus
            required={true}
            name='contactEmail'
            type='text'
            component={InputField}
            validate={validateEmail}
            label={t('company.details.form.contactEmail')}
            placeholder=''/>

          <Divider/>

          <Header as='h4'>{t('company.details.billingTitle')}</Header>
          <Field
            autoFocus
            required={true}
            name='billingCompanyName'
            type='text'
            component={InputField}
            validate={required}
            label={t('company.details.form.billingCompanyName')}
            placeholder=''/>
          <Field
            autoFocus
            required={true}
            name='billingEmail'
            type='text'
            component={InputField}
            validate={validateEmail}
            label={t('company.details.form.billingEmail')}
            placeholder=''/>
          <Field
            autoFocus
            required={true}
            name='billingAddressLineFirst'
            type='text'
            component={InputField}
            validate={required}
            label={t('company.details.form.billingAddressLineFirst')}
            placeholder=''/>

          <Field
            required={false}
            name='billingAddressLineSecond'
            type='text'
            component={InputField}
            label={t('company.details.form.billingAddressLineSecond')}
            placeholder=''/>

          <Form.Group widths='equal'>
            <Field
              required={true}
              name='billingAddressZipcode'
              type='text'
              component={InputField}
              validate={required}
              label={t('company.details.form.billingAddressZipcode')}
              placeholder=''/>

            <Field
              required={true}
              name='billingAddressCity'
              type='text'
              component={InputField}
              validate={required}
              label={t('company.details.form.billingAddressCity')}
              placeholder=''/>

            <Field
              required={true}
              options={countries}
              validate={required}
              component={DropdownField}
              name='billingAddressCountry'
              label={t('company.details.form.billingAddressCountry')}
              htmlFor='form-select-control-billingAddressCountry'
              search
              searchInput={{id: 'form-select-control-billingAddressCountry'}}
            />
          </Form.Group>

          <Field
            required={true}
            name='bankAccountNumber'
            type='text'
            component={InputField}
            label={t('company.details.form.bankAccountNumber')}
            placeholder=''/>

          <Field
            required={true}
            name='vatNumber'
            type='text'
            component={InputField}
            label={t('company.details.form.vatNumber')}
            placeholder=''/>

          {error &&
          <ErrorMessage message={error}/>
          }

          <Button
            positive
            floated='right'
            loading={submitting}
            disabled={pristine || submitting}>
            {t('buttons.save')}
          </Button>
          <Divider clearing hidden/>
        </Form>
      </Container>
    );
  }
}

const companySettingsForm = reduxForm({
  form: 'companySettingsForm',
  touchOnBlur: false
})(CompanySettingsForm);

// const selector = formValueSelector('companySettingsForm');

const mapStateToProps = (state) => {
  const {auth, companies, company} = state;
  let initialValues;
  let companyKey;
  if (company.loaded) {
    initialValues = company.data.details;
    companyKey = company.data.key;
  }
  return {
    auth,
    initialValues,
    companyKey,
    company,
    companies
  }
};

export default connect(mapStateToProps)(withTranslation()(companySettingsForm));




