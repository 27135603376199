import {
  COMPANY_CONTACT_INVITE_GET,
  COMPANY_CONTACT_INVITE_GET_FAIL,
  COMPANY_CONTACT_INVITE_GET_OK
} from "../actions";

const initialState = {
  data: null,

  loading: false,
  loaded: false,

  error: false,
  errorMessage: null,
};


export default function reducer(state = initialState, action) {
  switch (action.type) {

    case COMPANY_CONTACT_INVITE_GET: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }

    case COMPANY_CONTACT_INVITE_GET_OK: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload
      };
    }

    case COMPANY_CONTACT_INVITE_GET_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        errorMessage: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

