export const chartByDateOptions = {
  chart: {
    id: "chart-by-date",

    fontFamily: 'Lato, Arial, Helvetica, sans-serif',

    title: {
      align: 'center',
      style: {
        fontSize: '18px',
        color: '#263238'
      },
    },

    animations: {
      enabled: true,
      easing: 'easeinout',
      speed: 500,
      animateGradually: {
        enabled: false
      },
      dynamicAnimation: {
        enabled: true,
        speed: 350
      }
    },
    toolbar: false,
    zoom: {
      enabled: false,
      // type: 'x',
      // autoScaleYaxis: false,
      // zoomedArea: {
      //   fill: {
      //     color: '#90CAF9',
      //     opacity: 0.4
      //   },
      //   stroke: {
      //     color: '#0D47A1',
      //     opacity: 0.4,
      //     width: 1
      //   }
      // }
    }
  },
  dataLabels: {
    enabled: true,
  },
  stroke: {
    show: true,
    // curve: 'smooth',
    lineCap: 'butt',
    colors: undefined,
    width: 2,
    dashArray: 0,
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5
    },
  },
  legend: {
    show: true,
    showForSingleSeries: false,
    showForNullSeries: false,
    showForZeroSeries: false,
    position: 'bottom',
    horizontalAlign: 'left',
    floating: false,
    onItemClick: {
      toggleDataSeries: true
    },
    onItemHover: {
      highlightDataSeries: true
    },
  }
};

export const chartByGameOptions = {
  chart: {
    id: "chart-by-game",

    fontFamily: 'Lato, Arial, Helvetica, sans-serif',

    animations: {
      enabled: true
    },
    toolbar: false,
  },
  dataLabels: {
    enabled: false,
  },
  grid: {
    borderColor: '#e7e7e7',
    row: {
      colors: ['#f3f3f3', 'transparent'],
      opacity: 0.5
    },
  },
};