import {
  ACCOUNT_GET_FAIL,
  ACCOUNT_PASSWORD_RESET_FAIL,
  ACCOUNT_PASSWORD_RESET_OK,
  ACCOUNT_SEND_PASSWORD_RESET_FAIL,
  ACCOUNT_SEND_PASSWORD_RESET_OK,
  AUTH_LOGIN_FAIL,
  AUTH_LOGIN_OK,
  AUTH_LOGOUT,
  AUTH_LOGOUT_OK
} from "../actions";

import {TOKEN} from "../settings";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const initialState = {
  authenticating: false,
  authenticated: !!cookies.get(TOKEN),

  error: false,
  errorMessage: null
};

export default function reducer(state = initialState, action) {

  switch (action.type) {

    case AUTH_LOGIN_OK: {
      const {token} = action.payload;
      cookies.set(
        TOKEN, token, {
          path: '/',
          domain: process.env.REACT_COOKIE_DOMAIN,
          maxAge: 2592000
        }
      );

      return {
        ...state,
        authenticating: false,
        authenticated: true,
        data: token,
        error: false,
        errorMessage: null
      }
    }

    case AUTH_LOGIN_FAIL: {
      return {
        ...state,
        authenticating: false,
        authenticated: false,
        error: true,
        errorMessage: action.message
      }
    }

    case AUTH_LOGOUT:
    case AUTH_LOGOUT_OK:
    case ACCOUNT_GET_FAIL: {
      cookies.remove(
        TOKEN, {
          path: '/',
          domain: process.env.REACT_COOKIE_DOMAIN
        }
      );
      return {
        ...state,
        authenticated: false
      }
    }

    // unused because of Saga Promise-resolve/reject
    case ACCOUNT_SEND_PASSWORD_RESET_FAIL:
    case ACCOUNT_SEND_PASSWORD_RESET_OK:
    case ACCOUNT_PASSWORD_RESET_OK:
    case ACCOUNT_PASSWORD_RESET_FAIL: {
      return state;
    }

    default: {
      return state;
    }
  }
}
