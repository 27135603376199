import {COMPANY_GET, GAME_LIST, GAME_LIST_FAIL, GAME_LIST_OK} from "../actions";


const initialState = {
  data: null,

  loading: false,
  loaded: false,

  error: false,
  errorMessage: null,
};


export default function reducer(state = initialState, action) {
  switch (action.type) {

    case COMPANY_GET: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
        data: null
      };
    }

    case GAME_LIST: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }

    case GAME_LIST_OK: {
      let data = {};
      action.payload.forEach(g => {
        data[g.key] = g;
      });
      return {
        ...state,
        loading: false,
        loaded: true,
        data
      };
    }

    case GAME_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        errorMessage: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

