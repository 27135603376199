import React, {Fragment} from 'react'
import {Dropdown, Image, Menu} from 'semantic-ui-react'
import CoolGamesLogo from '../static/img/logo-coolgames-beta.png';
import {withTranslation} from "react-i18next";
import {AUTH_LOGOUT, COMPANY_SET_CURRENT_KEY} from "../actions";
import {Link, NavLink, withRouter} from "react-router-dom";
import {connect} from 'react-redux';
import ConfirmModal from "../ui/confirm-modal";

class MainMenu extends React.Component {

  constructor(props) {
    super(props);
    this.handleChangeCompany = this.handleChangeCompany.bind(this);
  }

  handleChangeCompany(e, {value}) {
    this.props.dispatch({type: COMPANY_SET_CURRENT_KEY, payload: value});
  }

  render() {
    const {t, account, dispatch, companies, company} = this.props;
    const {REACT_APP_HQ_URL} = process.env;

    const menuTitle = `${account.data.username} (${company.data.name})`;

    let companiesDropdown;
    if (companies.loaded && companies.data.length > 1) {
      companiesDropdown = (
        <Dropdown
          scrolling
          item
          text={t('companies.title')}
          onChange={this.handleChangeCompany}
          options={Object.keys(companies.data).map((key, index) => {
            const company = companies.data[key];
            return {
              key: company.key,
              value: company.key,
              text: company.name
            }
          })}/>
      )
    }

    return (
      <Menu fixed='top' inverted id='main-menu'>
        <Menu.Item header>
          <Image size='tiny' src={CoolGamesLogo} style={{marginRight: '1.5em'}}/>
        </Menu.Item>
        {company.data.completed &&
        <Fragment>
          <Menu.Item as={NavLink} exact to='/'>{t('dashboard.title')}</Menu.Item>
          <Menu.Item as={NavLink} to='/invoices'>{t('invoices.title')}</Menu.Item>
          <Menu.Item as={NavLink} to='/games'>{t('games.title')}</Menu.Item>
        </Fragment>
        }
        <Menu.Menu position='right'>
          {companiesDropdown}
          <Dropdown item simple text={menuTitle}>
            <Dropdown.Menu>
              <Dropdown.Header></Dropdown.Header>
              <Dropdown.Item as={Link} to='/settings'>{t('settings.title')}</Dropdown.Item>
              {account.data.isStaff &&
              <Fragment>
                <Dropdown.Divider/>
                <a href={REACT_APP_HQ_URL} className='item'>
                  Admin Dashboard
                </a>
              </Fragment>
              }
              <Dropdown.Header></Dropdown.Header>
              {/*<Dropdown.Item as={Link} to='/account/change-password'>Change*/}
              {/*  password</Dropdown.Item>*/}
              <Dropdown.Divider/>
              <Dropdown.Item
                as={ConfirmModal}
                buttonText='authentication.buttons.logout'
                button={false}
                confirmText='authentication.confirmLogout.text'
                confirmTitle='authentication.confirmLogout.title'
                confirmButtonText='authentication.confirmLogout.buttons.logout'
                confirmedAction={() => dispatch({type: AUTH_LOGOUT})}>
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>
    );
  }
}

const mapStateToProps = ({account, companies, company}) => ({
  account, companies, company
});
export default withRouter(connect(mapStateToProps)(withTranslation()(MainMenu)));