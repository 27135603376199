import {
  CHART_DATA_ECPM_BY_DATE_GET,
  CHART_DATA_ECPM_BY_DATE_GET_FAIL,
  CHART_DATA_ECPM_BY_DATE_GET_OK,
  COMPANY_GET
} from "../actions";

const initialState = {
  data: null,

  loading: false,
  loaded: false,

  error: false,
  errorMessage: null,
};


export default function reducer(state = initialState, action) {
  switch (action.type) {

    case COMPANY_GET: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: false,
        data: null
      };
    }

    case CHART_DATA_ECPM_BY_DATE_GET: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false,
        data: null
      };
    }

    case CHART_DATA_ECPM_BY_DATE_GET_OK: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: {
          xaxis: {
            type: "datetime",
            categories: action.payload.dates
          },
          series: action.payload.series
        }
      };
    }

    case CHART_DATA_ECPM_BY_DATE_GET_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        errorMessage: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

