import React, {Component} from 'react'
import {Table} from "semantic-ui-react";
import Euro from "../../../ui/euro";
import {ECPM_DIVIDER} from "../../settings";
import Impressions from "./impressions";
import Revenue from "./revenue";


export default class ReportSum extends Component {
  render() {
    const {t, totalImpressions, totalNetRevenue, totalAverageECPM} = this.props;
    return (
      <Table.Row>
        <Table.HeaderCell>{t('main.sum')}</Table.HeaderCell>
        <Table.HeaderCell>
          <Impressions amount={totalImpressions}/>
        </Table.HeaderCell>
        <Table.HeaderCell>
          <Revenue amount={totalNetRevenue}/>
        </Table.HeaderCell>
        <Table.HeaderCell>
          {(!totalAverageECPM || totalAverageECPM === 0) && '–'}
          {totalAverageECPM !== 0 &&
          <Euro value={totalAverageECPM} divider={ECPM_DIVIDER} decimalScale={2}/>
          }
        </Table.HeaderCell>
      </Table.Row>
    )
  }
}
