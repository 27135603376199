import React, {Component} from 'react'
import Euro from "../../../ui/euro";
import {ECPM_DIVIDER} from "../../../settings";

export default class AverageECPM extends Component {
  render() {
    const {amount} = this.props;
    return !amount || amount === 0 ? '-' :
      <Euro value={amount} divider={ECPM_DIVIDER} decimalScale={2}/>;
  }
}
