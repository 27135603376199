import {call, put, take} from 'redux-saga/effects';

import apiClient from "../api-client";
import {
  COMPANY_DETAILS_PUT,
  COMPANY_DETAILS_PUT_FAIL,
  COMPANY_DETAILS_PUT_OK,
  COMPANY_GET,
  COMPANY_GET_FAIL,
  COMPANY_GET_OK,
  COMPANY_LIST,
  COMPANY_LIST_FAIL,
  COMPANY_LIST_OK,
} from "../actions";

function* listCompaniesWorker() {
  try {
    const response = yield call(apiClient.get, 'companies/');
    yield put({
      type: COMPANY_LIST_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: COMPANY_LIST_FAIL, payload: error.response.data
    });
  }
}

export function* listCompanies() {
  while (true) {
    yield take(COMPANY_LIST);
    yield call(listCompaniesWorker);
  }
}

function* getCompanyWorker({key}) {
  try {
    const response = yield call(apiClient.get, `companies/${key}/`);
    yield put({
      type: COMPANY_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: COMPANY_GET_FAIL, payload: error.response.data
    });
  }
}

export function* getCompany() {
  while (true) {
    const request = yield take(COMPANY_GET);
    yield call(getCompanyWorker, request);
  }
}

function* putCompanyDetailsWorker({key, data, resolve, reject}) {
  try {
    const response = yield call(apiClient.put, `companies/${key}/details/`, data);
    yield put({
      type: COMPANY_DETAILS_PUT_OK, payload: data
    });
    yield call(resolve);
  } catch (error) {
    yield put({type: COMPANY_DETAILS_PUT_FAIL});
    yield call(reject, error.response.data);
  }
}

export function* putCompanyDetails() {
  while (true) {
    const request = yield take(COMPANY_DETAILS_PUT);
    yield call(putCompanyDetailsWorker, request);
  }
}
