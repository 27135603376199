import i18n from "../i18n";
import store from "../store";


export const required = value => (value ? undefined : i18n.t('errors.required'));

export const isNumber = value => value && isNaN(Number(value)) ? i18n.t('errors.numberRequired') : undefined;

export const validateEmail = value => {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(value)
    ? i18n.t('errors.emailInvalid')
    : undefined;
};

export const validateTimeFormat = input => {
  if (!input) {
    // allow empty input
    return;
  }
  if (typeof input === "number") {
    // untouched
    return;
  }

  try {
    input = input.split(' ');
  } catch (e) {
    return i18n.t('errors.timeInvalid');
  }

  const state = store.getState();
  const {hoursInDay, daysInWeek} = state.board.data;

  const converters = {
    w: value => value * hoursInDay * 60 * daysInWeek,
    d: value => value * hoursInDay * 60,
    h: value => value * 60,
    m: value => value
  };

  const result = input.some(timeValue => {
    if (!timeValue || timeValue.length <= 1) {
      return false;
    }
    const key = timeValue.substr(timeValue.length - 1);
    let value = timeValue.substr(0, timeValue.length - 1);
    if (!converters[key]) {
      return false;
    }
    const m = converters[key](value);
    return !isNaN(m);

  });
  if (result) {
    return undefined;
  }
  return i18n.t('errors.timeInvalid');
};