import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {CURRENT_LANGUAGE_COOKIE_NAME} from "./settings";

import {registerLocale} from "i18n-iso-countries";
import * as i18nIsoCountriesJA from "i18n-iso-countries/langs/ja";
import * as i18nIsoCountriesEN from "i18n-iso-countries/langs/en";
import * as i18nIsoCountriesNL from "i18n-iso-countries/langs/nl";

// Registering languages for the countries.
registerLocale(i18nIsoCountriesJA);
registerLocale(i18nIsoCountriesEN);
registerLocale(i18nIsoCountriesNL);

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)

  .init({

    debug: false,

    lng: 'en',

    react: {
      useSuspense: false
    },

    preload: ['ja', 'en'],

    detection: {
      order: ['cookie'],
      lookupCookie: CURRENT_LANGUAGE_COOKIE_NAME,
      caches: ['cookie'],
    },

    backend: {
      loadPath: function (lngs, namespaces) {
        //console.log(lngs);
        return `/locale/{{lng}}.json`;
      },

      crossDomain: false,
      withCredentials: false,
    },

    fallbackLng: 'ja',

    interpolation: {
      escapeValue: false
    },

  });

export default i18n;