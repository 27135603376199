import {all} from 'redux-saga/effects';
import {login, logout} from "./auth";
import {
  changePassword,
  forgotPassword,
  getAccount,
  registerAccount,
  sendPasswordReset
} from "./account";
import {getCompany, listCompanies, putCompanyDetails} from "./company";
import {getCompanyTerms, putCompanyTerms} from "./company-terms";
import {getCompanyContactInvite} from "./company-contact-invite";
import {getGameCategoryList, getGameList} from "./games";
import {
  getChartDataECPMByDate,
  getChartDataECPMByGame,
  getChartDataImpressionsByDate,
  getChartDataImpressionsByGame,
  getChartDataRevenueByDate,
  getChartDataRevenueByGame,
  getDashboardTotals,
  getReportByDateList,
  getReportByGameList
} from "./dashboard";
import {getInvoiceList} from "./invoices";


export default function* rootSaga() {
  yield all([
    login(),
    logout(),

    forgotPassword(),
    sendPasswordReset(),
    changePassword(),

    getAccount(),
    registerAccount(),

    listCompanies(),
    getCompany(),
    getCompanyTerms(),
    putCompanyTerms(),

    getCompanyContactInvite(),
    putCompanyDetails(),

    getGameList(),
    getGameCategoryList(),
    getDashboardTotals(),

    getChartDataImpressionsByDate(),
    getChartDataImpressionsByGame(),

    getChartDataRevenueByDate(),
    getChartDataRevenueByGame(),

    getChartDataECPMByDate(),
    getChartDataECPMByGame(),

    getReportByDateList(),
    getReportByGameList(),

    getInvoiceList()

  ]);
}
