import React, {Component} from 'react'
import {Table} from "semantic-ui-react";
import Impressions from "./impressions";
import Revenue from "./revenue";
import AverageECPM from "./average-ecpm";


export default class ReportRow extends Component {
  render() {
    const {report} = this.props;
    return (
      <Table.Row>
        <Table.Cell>{report.date ? report.date : report.game.title}</Table.Cell>
        <Table.Cell><Impressions amount={report.impressions}/></Table.Cell>
        <Table.Cell><Revenue amount={report.revenue}/></Table.Cell>
        <Table.Cell><AverageECPM amount={report.ecpm}/></Table.Cell>
      </Table.Row>
    )
  }
}
