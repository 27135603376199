import axios from "axios";
import {TOKEN} from "./settings";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(config => {
  const token = cookies.get(TOKEN);
  if (token) {
    config.headers.Authorization = `${TOKEN} ${token}`;
  }
  return config;
});

export default apiClient;