import React, {Component} from 'react'
import {Container, List, Segment,} from 'semantic-ui-react'
import {withTranslation} from "react-i18next";

class MainFooter extends Component {

  render() {
    const {t} = this.props;
    return (
      <Segment inverted vertical className='footer'>
        <Container textAlign='center'>
          {/*<Grid divided inverted stackable>*/}
          {/*<Grid.Column width={3}>*/}
          {/*<Header inverted as='h4' content='Group 1'/>*/}
          {/*<List link inverted>*/}
          {/*<List.Item as='a'>Link One</List.Item>*/}
          {/*<List.Item as='a'>Link Two</List.Item>*/}
          {/*<List.Item as='a'>Link Three</List.Item>*/}
          {/*<List.Item as='a'>Link Four</List.Item>*/}
          {/*</List>*/}
          {/*</Grid.Column>*/}
          {/*<Grid.Column width={3}>*/}
          {/*<Header inverted as='h4' content='Group 2'/>*/}
          {/*<List link inverted>*/}
          {/*<List.Item as='a'>Link One</List.Item>*/}
          {/*<List.Item as='a'>Link Two</List.Item>*/}
          {/*<List.Item as='a'>Link Three</List.Item>*/}
          {/*<List.Item as='a'>Link Four</List.Item>*/}
          {/*</List>*/}
          {/*</Grid.Column>*/}
          {/*<Grid.Column width={3}>*/}
          {/*<Header inverted as='h4' content='Group 3'/>*/}
          {/*<List link inverted>*/}
          {/*<List.Item as='a'>Link One</List.Item>*/}
          {/*<List.Item as='a'>Link Two</List.Item>*/}
          {/*<List.Item as='a'>Link Three</List.Item>*/}
          {/*<List.Item as='a'>Link Four</List.Item>*/}
          {/*</List>*/}
          {/*</Grid.Column>*/}
          {/*<Grid.Column width={7}>*/}
          {/*<Header inverted as='h4' content='Footer Header'/>*/}
          {/*<p>*/}
          {/*Extra space for a call to action inside the footer that could help*/}
          {/*re-engage users.*/}
          {/*</p>*/}
          {/*</Grid.Column>*/}
          {/*</Grid>*/}
          <List horizontal inverted divided link size='small'>
            <List.Item
              as='a'
              href='https://www.coolgames.com/terms-of-use/'
              target='_blank'>
              {t('termsOfUse.title')}
            </List.Item>
            <List.Item
              as='a'
              href='https://www.coolgames.com/privacy-policy/'
              target='_blank'>
              {t('privacyPolicy.title')}
            </List.Item>
          </List>
        </Container>
      </Segment>
    );
  }
}

export default withTranslation()(MainFooter);