import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withTranslation} from 'react-i18next';
import {Container, Tab} from "semantic-ui-react";
import Loading from "../../ui/loading";
import CompanySettingsForm from './company-settings-form';
import TermsAndConditions from './terms-and-conditions';
import AccountSettings from './account-settings';

class Settings extends Component {

  componentDidMount() {
    window.scrollTo(0, 0);
  }


  render() {
    const {t, company} = this.props;

    if (!company.loaded) {
      return <Loading/>;
    }

    const companyTab = {
      menuItem: t('settings.companySettings.title'),
      render: () => (<Tab.Pane><CompanySettingsForm/></Tab.Pane>)
    };
    const termsTab = {
      menuItem: {
        key: 'terms',
        content: t('settings.termsAndConditions.title'),
      },
      render: () => (<Tab.Pane><TermsAndConditions readOnly={true}/></Tab.Pane>
      )
    };
    const accountTab = {
      menuItem: {
        key: 'account-settings',
        content: t('account.settings.title')
      },
      render: () => (
        <Tab.Pane><AccountSettings/></Tab.Pane>
      ),
    };

    let panes = [companyTab, accountTab];
    if (!company.data.hideTerms) {
      panes = [companyTab, termsTab, accountTab];
    }

    return (
      <Container className='page settings'>
        <Tab panes={panes}/>
      </Container>
    );
  }
}

const mapStateToProps = ({account, company}) => ({account, company});
export default connect(mapStateToProps)(withTranslation()(Settings));




