import React, {Component} from 'react';
import {connect} from "react-redux";
import Chart from "react-apexcharts";
import Loading from "../../ui/loading";
import {chartByGameOptions} from "./_chart-options";
import {withTranslation} from "react-i18next";


class ChartByGameImpressions extends Component {

  render() {
    const {t, dataByGameImpressions: {loaded, data}} = this.props;
    if (!loaded) {
      return <Loading/>
    }

    return (
      <Chart
        options={{
          ...chartByGameOptions,
          xaxis: data.xaxis,
          title: {
            text: t('charts.impressions.perGameTitle'),
            align: 'center', style: {fontSize: '16px'}
          }
        }}
        series={[data.series]}
        type="bar"
        height="450px" width="100%"
      />
    );

  }
}

const mapStateToProps = ({dataByGameImpressions}) => ({
  dataByGameImpressions
});
export default connect(mapStateToProps)(withTranslation()(ChartByGameImpressions));