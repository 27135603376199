import React, {Component} from 'react';
import {
  Container,
  Divider,
  Dropdown,
  Form,
  Icon,
  Segment,
  Statistic
} from 'semantic-ui-react'
import {DateInput} from "semantic-ui-calendar-react";
import {connect} from 'react-redux';
import {withTranslation} from "react-i18next";
import NumberFormat from 'react-number-format';
import {
  CHART_DATA_ECPM_BY_DATE_GET,
  CHART_DATA_ECPM_BY_GAME_GET,
  CHART_DATA_IMPRESSIONS_BY_DATE_GET,
  CHART_DATA_IMPRESSIONS_BY_GAME_GET,
  CHART_DATA_REVENUE_BY_DATE_GET,
  CHART_DATA_REVENUE_BY_GAME_GET,
  DASHBOARD_TOTALS_GET
} from "../../actions";
import Loading from "../../ui/loading";
import ChartByDateImpressions from "./chart-by-date-impressions";
import ChartByGameImpressions from "./chart-by-game-impressions";
import ChartByGameRevenue from "./chart-by-game-revenue";
import ChartByDateRevenue from "./chart-by-date-revenue";
import ChartByDateECPM from "./chart-by-date-ecpm";
import ChartByGameECPM from "./chart-by-game-ecpm";
import Euro from "../../ui/euro";
import moment from "moment";
import ReportsByDate from "./reports-by-date";
import ReportsByGame from "./reports-by-game";

import {
  DATA_SHOW_BY_DATE,
  DATA_SHOW_BY_GAME,
  ECPM_DIVIDER,
  REVENUE_DIVIDER
} from "../../settings";


class Dashboard extends Component {

  constructor(props) {
    super(props);

    const {t} = this.props;

    var mD = new Date();
    var sD = new Date();
    var eD = new Date();
    var tD = new Date();

    this.state = {
      showChart: 'impressions',

      minDate: moment(mD.setDate(mD.getDate() - 100)).format("YYYY-MM-DD"),
      startDate: moment(sD.setDate(sD.getDate() - 7)).format("YYYY-MM-DD"),
      endDate: moment(eD.setDate(eD.getDate() - 1)).format("YYYY-MM-DD"),
      todayDate: moment(tD.setDate(tD.getDate())).format("YYYY-MM-DD"),
      yesterdayDate: moment(tD.setDate(tD.getDate() - 1)).format("YYYY-MM-DD"),

      dateSelectOptions: [
        {key: 0, text: t('dashboard.filters.dateSelectOptions.custom'), value: 'custom'},
        {key: 1, text: t('dashboard.filters.dateSelectOptions.pastSevenDays'), value: 'seven-days'},
        {key: 2, text: t('dashboard.filters.dateSelectOptions.pastThirtyDays'), value: 'thirty-days'},
        {key: 3, text: t('dashboard.filters.dateSelectOptions.thisMonth'), value: 'this-month'},
        {key: 4, text: t('dashboard.filters.dateSelectOptions.lastMonth'), value: 'last-month'},
        {key: 5, text: t('dashboard.filters.dateSelectOptions.allTime'), value: 'all'}
      ],

      selectedDateOption: 'seven-days',

      showBySelectOptions: [
        {key: 1, text: t('dashboard.filters.displayOptions.showByDate'), value: DATA_SHOW_BY_DATE},
        {key: 2, text: t('dashboard.filters.displayOptions.showByGame'), value: DATA_SHOW_BY_GAME},
      ],

      showBy: DATA_SHOW_BY_DATE
    };

    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.setDateRange = this.setDateRange.bind(this);
    this.setShowBy = this.setShowBy.bind(this);
  }

  componentDidMount() {
    const {auth: {authenticated}, dashboard} = this.props;
    if (authenticated && !dashboard.loaded) {
      this.dispatchAll();
    }
    window.scrollTo(0, 0);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {startDate, endDate, showBy} = this.state;
    const {startDate: prevStartDate, endDate: prevEndDate, showBy: prevShowBy} = prevState;
    if (startDate !== prevStartDate || endDate !== prevEndDate || showBy !== prevShowBy) {
      this.dispatchAll();
    }
  }

  dispatchAll() {
    const {companies: {currentCompanyKey}, dispatch} = this.props;
    const {startDate, endDate, showBy} = this.state;

    dispatch({
      type: DASHBOARD_TOTALS_GET,
      currentCompanyKey, startDate, endDate
    });

    if (showBy === DATA_SHOW_BY_DATE) {
      dispatch({
        type: CHART_DATA_IMPRESSIONS_BY_DATE_GET,
        currentCompanyKey, startDate, endDate
      });
      dispatch({
        type: CHART_DATA_REVENUE_BY_DATE_GET,
        currentCompanyKey, startDate, endDate
      });
      dispatch({
        type: CHART_DATA_ECPM_BY_DATE_GET,
        currentCompanyKey, startDate, endDate
      });
    }
    if (showBy === DATA_SHOW_BY_GAME) {
      dispatch({
        type: CHART_DATA_IMPRESSIONS_BY_GAME_GET,
        currentCompanyKey, startDate, endDate
      });
      dispatch({
        type: CHART_DATA_REVENUE_BY_GAME_GET,
        currentCompanyKey, startDate, endDate
      });
      dispatch({
        type: CHART_DATA_ECPM_BY_GAME_GET,
        currentCompanyKey, startDate, endDate
      });
    }
  }

  setStartDate(e, {value}) {
    this.setState({
      selectedDateOption: 'custom',
      startDate: value
    });
  }

  setEndDate(e, {value}) {
    this.setState({
      selectedDateOption: 'custom',
      endDate: value
    });
  }

  setShowBy(e, {value}) {
    this.setState({showBy: value})
  }

  setDateRange(e, {value}) {
    this.setState({selectedDateOption: value});
    let startDate;
    let endDate = moment(new Date().setDate(new Date().getDate() - 1)).format("YYYY-MM-DD");
    switch (value) {
      case 'all': {
        startDate = moment(new Date().setDate(new Date().getDate() - 90)).format("YYYY-MM-DD");
        break;
      }
      case 'seven-days':
      default: {
        startDate = moment(new Date().setDate(new Date().getDate() - 7)).format("YYYY-MM-DD");
        break;
      }
      case 'thirty-days': {
        startDate = moment(new Date().setDate(new Date().getDate() - 30)).format("YYYY-MM-DD");
        break;
      }
      case 'this-month': {
        startDate = moment(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        ).format("YYYY-MM-DD");
        break;
      }
      case 'last-month': {
        startDate = moment(new Date()).subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        endDate = moment(new Date()).subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        break;
      }
    }
    this.setState({startDate, endDate});
  }

  render() {
    const {t, companies, dashboard} = this.props;
    const {
      showChart,
      minDate,
      startDate,
      endDate,
      yesterdayDate,
      dateSelectOptions,
      selectedDateOption
    } = this.state;
    const {showBySelectOptions, showBy} = this.state;

    let dashboardChart;

    if (showChart === 'impressions') {
      if (showBy === DATA_SHOW_BY_DATE) {
        dashboardChart = <ChartByDateImpressions
          currentCompanyKey={companies.currentCompanyKey}
          startDate={startDate}
          endDate={endDate}/>;
      } else {
        dashboardChart = <ChartByGameImpressions
          currentCompanyKey={companies.currentCompanyKey}
          startDate={startDate}
          endDate={endDate}/>;
      }
    }
    if (showChart === 'revenue') {
      if (showBy === DATA_SHOW_BY_DATE) {
        dashboardChart = <ChartByDateRevenue
          currentCompanyKey={companies.currentCompanyKey}
          startDate={startDate}
          endDate={endDate}/>;

      } else {
        dashboardChart = <ChartByGameRevenue
          currentCompanyKey={companies.currentCompanyKey}
          startDate={startDate}
          endDate={endDate}/>
      }
    } else if (showChart === 'ecpm') {
      if (showBy === DATA_SHOW_BY_DATE) {
        dashboardChart = <ChartByDateECPM
          currentCompanyKey={companies.currentCompanyKey}
          startDate={startDate}
          endDate={endDate}/>;
      } else {
        dashboardChart = <ChartByGameECPM
          currentCompanyKey={companies.currentCompanyKey}
          startDate={startDate}
          endDate={endDate}/>;
      }
    }

    return (
      <Container className='main-content dashboard'>

        <Segment.Group horizontal className='date-inputs'>
          <Segment>
            <Form>
              <div className="field date-input">
                <label>{t('dashboard.filters.displayOptions.title')}</label>
                <Dropdown
                  placeholder={t('dashboard.filters.displayOptions.placeholder')}
                  className='group-by-select'
                  selection
                  options={showBySelectOptions}
                  onChange={this.setShowBy}
                  value={showBy}
                />
              </div>
            </Form>
          </Segment>
          <Segment>
            <Form>
              <div className="field date-input">
                <label>{t('dashboard.filters.dateSelectOptions.title')}</label>
                <Dropdown
                  placeholder={t('dashboard.filters.dateSelectOptions.placeholder')}
                  className='date-select'
                  selection
                  options={dateSelectOptions}
                  onChange={this.setDateRange}
                  value={selectedDateOption}
                />
              </div>
            </Form>
          </Segment>
          <Segment>
            <Form>
              <DateInput
                label={t('dashboard.filters.dateSelectOptions.startDateTitle')}
                className='date-input'
                animation='none'
                name="startDate"
                placeholder="Start date"
                value={startDate}
                dateFormat={"YYYY-MM-DD"}
                iconPosition="left"
                minDate={minDate}
                maxDate={endDate}
                onChange={this.setStartDate}
              />
            </Form>
          </Segment>
          <Segment>
            <Form>
              <DateInput
                label={t('dashboard.filters.dateSelectOptions.endDateTitle')}
                className='date-input'
                animation='none'
                name="endDate"
                placeholder="End date"
                value={endDate}
                dateFormat={"YYYY-MM-DD"}
                iconPosition="left"
                minDate={startDate}
                maxDate={yesterdayDate}
                onChange={this.setEndDate}
              />
            </Form>
          </Segment>
        </Segment.Group>

        {!dashboard.loaded && <Loading/>}
        {dashboard.loaded &&
        <Statistic.Group widths="3">
          <Statistic
            className={`${showChart === 'impressions' ? 'active' : ''}`}
            onClick={() => this.setState({showChart: 'impressions'})}>
            <Statistic.Value>
              <NumberFormat
                value={dashboard.data.totalImpressions}
                thousandSeparator={','}
                displayType={'text'}
              />

            </Statistic.Value>
            <Statistic.Label>
              <Icon name='chart line' color='green'/>
              {t('dashboard.impressions.title')}
            </Statistic.Label>
          </Statistic>

          <Statistic
            className={`${showChart === 'revenue' ? ' active' : ''}`}
            onClick={() => this.setState({showChart: 'revenue'})}>
            <Statistic.Value>
              <Euro
                value={dashboard.data.totalRevenue}
                divider={REVENUE_DIVIDER}
                decimalScale={2}
              />
            </Statistic.Value>
            <Statistic.Label>
              <Icon name='chart line' color='green'/>
              {t('dashboard.revenue.title')}
            </Statistic.Label>
          </Statistic>

          <Statistic
            className={`${showChart === 'ecpm' ? ' active' : ''}`}
            onClick={() => this.setState({showChart: 'ecpm'})}>
            <Statistic.Value>
              <Euro
                value={dashboard.data.totalEcpm}
                divider={ECPM_DIVIDER}
                decimalScale={2}
              />
            </Statistic.Value>
            <Statistic.Label>
              <Icon name='chart line' color='green'/>
              {t('dashboard.ecpm.title')}
            </Statistic.Label>
          </Statistic>
        </Statistic.Group>
        }

        <Segment className='chart'>
          {!dashboard.loaded && <Loading/>}
          {dashboard.loaded && dashboardChart}
        </Segment>

        <Divider hidden/>

        {showBy === DATA_SHOW_BY_DATE &&
        <ReportsByDate startDate={startDate} endDate={endDate}/>
        }
        {showBy === DATA_SHOW_BY_GAME &&
        <ReportsByGame startDate={startDate} endDate={endDate}/>
        }
      </Container>
    );
  }
}


const mapStateToProps = ({auth, companies, dashboard, dashboardDataImpressions, dashboardDataRevenue, dashboardDataECPM}) => ({
  auth,
  companies,
  dashboard,

  dashboardDataImpressions,
  dashboardDataRevenue,
  dashboardDataECPM
});

export default connect(mapStateToProps)(withTranslation()(Dashboard));