import React, {Component} from 'react';
import {Loader} from "semantic-ui-react";


export default class Loading extends Component {

  render() {
    const {size='small'} = this.props;
    return (
      <Loader active size={size} inline='centered'/>
    );
  }
}
