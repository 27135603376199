import React, {Component, Fragment} from 'react';
import i18n from "../i18n";
import {
  Checkbox as CheckboxComponent,
  Dropdown as DropdownComponent,
  Form,
  Input as InputComponent,
  Label,
  Radio as RadioComponent,
  Select as SelectComponent,
  TextArea as TextAreaComponent
} from 'semantic-ui-react';
import classNames from 'classnames';
import {DateInput} from "semantic-ui-calendar-react";
import {Button} from "reactstrap";
import Loading from "../ui/loading";


export const lessThan = otherField => (value, previousValue, allValues) => value < allValues[otherField] ? value : previousValue;
export const greaterThan = otherField => (value, previousValue, allValues) => value > allValues[otherField] ? value : previousValue;

export const lessOrEqualCharsThan = maxChars => (chars, prevChars) => chars.length <= maxChars ? chars : prevChars;

export const sanitizeProjectCode = maxChars => (
  (chars, prevChars) => {
    return chars.length <= maxChars ? chars.replace(/[^a-z0-9]/gi, '').toUpperCase() : prevChars;
  }
);


export class InputField extends Component {

  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.focusInput = this.focusInput.bind(this);
  }

  focusInput() {
    this.inputRef.current.focus();
  }


  render() {
    const {input, label, counter, required, width, inline, meta: {touched, error}, ...rest} = this.props;

    let labelContent = <label>{label}</label>;
    if (counter !== undefined) {
      labelContent = <Fragment>
        <label style={{float: 'left'}}>{label}</label>
        <div
          style={{float: 'right'}}
          className={classNames({
            'field-counter': true,
            'warning': counter < 5
          })}>
          {counter}
        </div>
      </Fragment>;
    }

    return (
      <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
        {labelContent}
        <InputComponent
          ref={this.inputRef}
          required={required}
          {...input}
          {...rest} />
        {touched && error &&
        <Label basic color="red" pointing>
          {i18n.t(error)}
        </Label>
        }
      </Form.Field>
    );
  }
}

export const Input = ({input, required, meta: {touched, error}, ...rest}) => (
  <Input required={required} {...input} {...rest} />
);

export const TextAreaField = ({input, label, counter, required, width, inline, meta: {touched, error}, ...rest}) => {
  let labelContent = <label>{label}</label>;
  if (counter !== undefined) {
    labelContent = <Fragment>
      <label style={{float: 'left'}}>{label}</label>
      <div
        style={{float: 'right'}}
        className={classNames({
          'field-counter': true,
          'warning': counter < 5
        })}>
        {counter}
      </div>
    </Fragment>;
  }

  return (
    <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
      {labelContent}
      <TextAreaComponent required={required} {...input} {...rest} />
      {touched && error && <Label basic color="red" pointing>{error}</Label>
      }
    </Form.Field>
  );
};


export const LabelInputField = ({input, required, width, meta: {touched, error}, ...rest}) => ( //eslint-disable-line
  <Form.Field error={!!(touched && error)} required={required} width={width}>
    <Input required={required} {...input} {...rest} />
    {touched && error &&
    <Label basic color="red" pointing>
      {error}
    </Label>
    }
  </Form.Field>
);


export const TextArea = ({input, required, meta: {touched, error}, ...rest}) => (
  <TextAreaComponent required={required} {...input} {...rest} />
);


export const SelectField = ({input, label, required, width, inline, options, meta: {touched, error}, ...custom}) => (
  <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
    {label && <label>{label}</label>}
    <SelectComponent
      search
      value={input.value}
      required={required}
      options={options.map(
        (option) => (
          {...option, text: i18n.t(option.text)}
        )
      )}
      onChange={(event, data) => input.onChange(data.value)}
      {...custom}
    />
    {touched && error &&
    <Label basic color="red" pointing>
      {error}
    </Label>
    }
  </Form.Field>
);

export const Select = ({input, required, options, meta: {touched, error}, ...rest}) => (
  <SelectComponent
    search
    value={input.value}
    required={required}
    options={options}
    onChange={(event, data) => input.onChange(data.value)}
    {...rest}
  />
);

export class CheckboxField extends Component {
  render() {
    const {input, label, width, meta: {touched, error}, ...rest} = this.props;

    return (
      <Form.Field error={!!(touched && error)} width={width}>
        <CheckboxComponent
          label={label}
          width={width}
          checked={!!input.value}
          onClick={(event, data) => input.onChange(data.checked)}
          {...rest}
        />
        {touched && error &&
        <Label basic color="red">
          {i18n.t(error)}
        </Label>
        }
      </Form.Field>
    );
  }
}


export class DropdownField extends Component {
  render() {
    const {
      input, label, clearable, required, search = false,
      width, inline, options, meta: {touched, error}, ...custom
    } = this.props;
    return (
      <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
        {label && <label>{label}</label>}
        <DropdownComponent
          search={search}
          value={input.value}
          required={required}
          clearable={clearable}
          options={options}
          onChange={(event, data) => input.onChange(data.value)}
          {...custom}
        />
        {touched && error &&
        <Label basic color="red" pointing>
          {error}
        </Label>
        }
      </Form.Field>
    );
  }
}


export const Dropdown = ({input, required, clearable, options, meta: {touched, error}, ...rest}) => (
  <DropdownComponent
    search
    value={input.value}
    required={required}
    clearable={clearable}
    options={options}
    onChange={(event, data) => input.onChange(data.value)}
    {...rest}
  />
);


export class DateField extends Component {

  render() {
    const {
      input: {value, onChange}, label, required, width, inline, options, meta: {touched, error}, ...custom
    } = this.props;

    return (
      <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
        {label && <label>{label}</label>}
        <Form.Group>
          <DateInput
            value={value}
            dateFormat={'YYYY-MM-DD'}
            options={options}
            width={16}
            onChange={(e, {value}) => onChange(value)}
            {...custom}
          />

        </Form.Group>
        {touched && error &&
        <Label basic color="red" pointing>
          {error}
        </Label>
        }
      </Form.Field>
    );
  }
}


export const ToggleField = ({input, label, defaultChecked, width}) => (
  <Form.Field
    control={RadioComponent}
    toggle
    label={label}
    checked={!!input.value}
    defaultChecked={defaultChecked}
    onClick={(event, data) => input.onChange(data.checked)}
    width={width}
  />
);

export const Toggle = ({input, label, defaultChecked}) => (
  <RadioComponent
    toggle
    label={label}
    checked={!!input.value}
    defaultChecked={defaultChecked}
    onClick={(event, data) => input.onChange(data.checked)}
  />
);

export const Radio = ({input, label, meta: {touched, error}, ...custom}) => (
  <RadioComponent
    label={label}
    checked={!!input.value}
    onClick={(event, data) => input.onChange(data.checked)}
    {...custom}
  />
);

export const RadioField = ({input, label, width, meta: {touched, error}, ...custom}) => (
  <Form.Field
    control={RadioComponent}
    label={label}
    width={width}
    checked={!!input.value}
    onClick={(event, data) => input.onChange(data.checked)}
    {...custom}
  />
);


export class RadioGroup extends Component {

  render() {
    const {inline, input, choices, label, disabled, width, meta: {touched, error}} = this.props;

    return (
      <Form.Group width={width}>
        {label && <label>{label}</label>}
        {choices.map(({value, text}) => (
          <Form.Field
            error={touched && error}
            disabled={disabled}
            inline={inline}
            control='input' type='radio'
            key={value}
            label={i18n.t(text)}
            name={input.name}
            checked={input.value === value}
            onChange={() => input.onChange(value)}
          />
        ))}
        {touched && error &&
        <Label basic color="red" pointing>
          {i18n.t(error)}
        </Label>
        }
      </Form.Group>
    );
  }
}


export const UploadField = ({label, input, required, width, inline, meta: {touched, error}, ...rest}) => {
  delete input.value; //Delete value from input
  return (
    <Form.Field error={touched && error} required={required} width={width} inline={inline}>
      {label && <label>{label}</label>}
      <Input
        type="file"
        {...input}
        {...rest}
      />
      {touched && error &&
      <Label basic color="red" pointing>
        {error}
      </Label>
      }
    </Form.Field>
  );
};

export const Upload = ({input, required, meta: {touched, error}, ...rest}) => {
  delete input.value;
  return (
    <Input
      required={required}
      type="file"
      {...input}
      {...rest}
    />
  );
};

export const RangeField = ({input, label, width, inline, min, max, required, meta: {touched, error}, ...rest}) => (
  <Form.Field error={!!(touched && error)} required={required} width={width} inline={inline}>
    <label>
      {label} : {input.value}
    </label>
    <input type="range" required={required} min={min} max={max} {...input} {...rest} />
    {touched && error &&
    <Label basic color="red" pointing>
      {error}
    </Label>
    }
  </Form.Field>
);

export const Range = ({input, min, max, required, meta: {touched, error}, ...rest}) => (
  <input type="range" required={required} min={min} max={max} {...input} {...rest} />
);


export class FormSubmitButton extends Component {
  render() {
    const {floatRight = false, title, pristine, submitting, error} = this.props;

    const buttonClass = classNames({
      'mt-4 mb-4': !floatRight,
      'float-right': floatRight,
      'submitting': submitting,
      'error': error
    });

    return (
      <Button
        color="submit" className={buttonClass}
        disabled={pristine || submitting}>
        {submitting &&
        <Loading color='#ffffff' width={20} height={20}/>
        }
        {!submitting &&
        <Fragment>{title}</Fragment>
        }
      </Button>
    )
  }

}


export class LabelRequired extends Component {
  render() {
    return (
      <Label size="tiny">{i18n.t('main.required')}</Label>
    )
  }
}

