import {call, put, take} from 'redux-saga/effects';

import apiClient from "../api-client";
import {
  COMPANY_TERMS_GET,
  COMPANY_TERMS_GET_FAIL,
  COMPANY_TERMS_GET_OK,
  COMPANY_TERMS_PUT,
  COMPANY_TERMS_PUT_FAIL,
  COMPANY_TERMS_PUT_OK,
} from "../actions";


function* getCompanyTermsWorker({companyKey}) {
  try {
    const response = yield call(apiClient.get, `companies/${companyKey}/terms/`);
    yield put({
      type: COMPANY_TERMS_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: COMPANY_TERMS_GET_FAIL, payload: error.response.data
    });
  }
}

export function* getCompanyTerms() {
  while (true) {
    const request = yield take(COMPANY_TERMS_GET);
    yield call(getCompanyTermsWorker, request);
  }
}


function* putCompanyTermsWorker({companyKey, resolve, reject}) {
  try {
    yield call(apiClient.put, `companies/${companyKey}/terms/`, {agreedToTerms: true});
    yield put({type: COMPANY_TERMS_PUT_OK});
    yield call(resolve);
  } catch (error) {
    yield put({
      type: COMPANY_TERMS_PUT_FAIL
    });
    yield call(reject);
  }
}

export function* putCompanyTerms() {
  while (true) {
    const request = yield take(COMPANY_TERMS_PUT);
    yield call(putCompanyTermsWorker, request);
  }
}
