import React from "react";
import ReactDOM from "react-dom";
import {browserHistory, BrowserRouter} from "react-router-dom";
import 'semantic-ui-css/semantic.min.css';
import "./static/css/styles.scss";
import {I18nextProvider} from 'react-i18next';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'


import {Provider} from 'react-redux';
import ReduxToastr from 'react-redux-toastr'
import store from './store';
import App from "./components/app";

import i18n from './i18n';
import {CookiesProvider} from "react-cookie";


ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <BrowserRouter basename="/" history={browserHistory}>
        <I18nextProvider i18n={i18n}>
        <App/>
        </I18nextProvider>
      </BrowserRouter>
    </CookiesProvider>
    <ReduxToastr
      timeOut={4000}
      newestOnTop={false}
      preventDuplicates
      position="bottom-left"
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar={false}
      closeOnToastrClick
    />
  </Provider>,
  document.getElementById("app")
);