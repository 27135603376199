
export const DEBUG = process.env.REACT_APP_HOST_ENV === 'development';
export const TOKEN = 'JWT';

export const CURRENT_COMPANY_KEY_COOKIE_NAME = 'ccmp';
export const CURRENT_LANGUAGE_COOKIE_NAME = 'lng';

export const REVENUE_DIVIDER = 1000000;
export const ECPM_DIVIDER = 1000000;

export const DATA_SHOW_BY_DATE = 'show-by-date';
export const DATA_SHOW_BY_GAME = 'show-by-game';