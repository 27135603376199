import {
  AUTH_LOGOUT_OK,
  COMPANY_LIST,
  COMPANY_LIST_FAIL,
  COMPANY_LIST_OK,
  COMPANY_SET_CURRENT_KEY
} from "../actions";


import {CURRENT_COMPANY_KEY_COOKIE_NAME, TOKEN} from "../settings";
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const initialState = {
  data: null,
  currentCompanyKey: cookies.get(CURRENT_COMPANY_KEY_COOKIE_NAME),

  loading: false,
  loaded: false,

  error: false,
  errorMessage: null,
};


export default function reducer(state = initialState, action) {
  switch (action.type) {

    case COMPANY_LIST: {
      return {
        ...state,
        loading: true,
        loaded: false,
        error: false
      };
    }

    case COMPANY_LIST_OK: {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: false,
        data: action.payload,
        current: 0
      };
    }

    case COMPANY_LIST_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        errorMessage: action.payload
      };
    }

    case AUTH_LOGOUT_OK: {
      cookies.remove(
        CURRENT_COMPANY_KEY_COOKIE_NAME, {
          path: '/',
          domain: process.env.REACT_COOKIE_DOMAIN
        }
      );
      return {
        ...state,
        currentCompanyKey: null
      };
    }

    case COMPANY_SET_CURRENT_KEY: {
      cookies.set(
        CURRENT_COMPANY_KEY_COOKIE_NAME,
        action.payload, {
          path: '/',
          domain: process.env.REACT_COOKIE_DOMAIN,
          maxAge: 2592000
        }
      );
      return {
        ...state,
        currentCompanyKey: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

