import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm, SubmissionError} from 'redux-form';
import {Link, Redirect} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {ACCOUNT_PASSWORD_FORGOT} from "../../actions";
import {InputField} from "../../helpers/forms";
import {
  Button,
  Container,
  Divider,
  Form,
  Header,
  Image
} from "semantic-ui-react";
import CoolGamesLogo from "../../static/img/logo-coolgames.png";
import {required} from "../../helpers/validation";


class ForgotPassword extends Component {

  constructor(props) {
    super(props);
    this.state = {
      success: false
    };
    this.submit = this.submit.bind(this);
  }

  submit(values) {
    return new Promise((resolve, reject) => {
      this.props.dispatch({
        type: ACCOUNT_PASSWORD_FORGOT,
        data: values,
        resolve,
        reject
      });
    }).then(() => {
      this.setState({
        success: true
      })
    }).catch((error) => {
      throw new SubmissionError({
        ...error.errors
      });
    })
  };

  render() {
    const {t, auth, handleSubmit, pristine, submitting, error} = this.props;
    const {success} = this.state;

    if (auth.authenticated) {
      return (
        <Redirect to='/'/>
      )
    }
    if (success) {
      return (
        <Container text className='page'>
          <Image src={CoolGamesLogo} size='medium' centered/><br/>
          <h2>{t('authentication.forgotPassword.resetMailSent.title')}</h2>
          <p>{t('authentication.forgotPassword.resetMailSent.text')}</p>
          <Divider/>
          <Link to='/'>
            {t('buttons.back')}
          </Link>
        </Container>
      )
    }

    return (
      <Container text className='page'>
        <Image src={CoolGamesLogo} size='medium' centered/><br/>
        <Header as='h2'>
          {t('authentication.forgotPassword.title')}
        </Header>
        <p>{t('authentication.forgotPassword.text')}</p>
        <Divider/>
        <Form onSubmit={handleSubmit(this.submit)} noValidate>

          <Field
            required={true}
            name='email'
            type='email'
            component={InputField}
            validate={required}
            label={t('authentication.form.email')}
            placeholder={t('authentication.form.email')}/>

          <Link to='/'>
            {t('buttons.back')}
          </Link>

          <Button
            positive
            floated='right'
            loading={submitting}
            disabled={pristine || submitting}>
            {t('authentication.buttons.sendPasswordResetLink')}
          </Button>

        </Form>
      </Container>
    );
  }
}

const mapStateToProps = ({auth, account}) => ({auth, account});
const forgotPasswordForm = reduxForm({form: 'forgotPasswordForm'})(ForgotPassword);
export default connect(mapStateToProps)(withTranslation()(forgotPasswordForm));

