import React, {Component} from 'react'
import {connect} from 'react-redux';
import Loading from "../../ui/loading";
import {withTranslation} from "react-i18next";
import {Container, Header, Icon, Table} from "semantic-ui-react";
import Euro from "../../ui/euro";
import {INVOICE_LIST} from "../../actions";
import moment from "moment";


class InvoiceRow extends Component {
  render() {
    const {t, companyKey, invoice} = this.props;
    return (
      <Table.Row>
        <Table.Cell>
          {moment(invoice.date).startOf('month').format('YYYY-MM-DD')}
        </Table.Cell>
        <Table.Cell>
          {moment(invoice.date).endOf('month').format('YYYY-MM-DD')}
        </Table.Cell>
        <Table.Cell>
          {invoice.amount ? <Euro value={invoice.amount} divider={1}/> : '–'}
        </Table.Cell>
        <Table.Cell>
          {invoice.status === 1 && t('invoices.status.pendingTitle')}
          {invoice.status === 2 && t('invoices.status.approvedTitle')}
          {invoice.status === 3 && t('invoices.status.paidTitle')}
        </Table.Cell>
        <Table.Cell>
          {[2, 3].includes(invoice.status) &&
          <a
            href={`${process.env.REACT_APP_API_URL}companies/${companyKey}/invoice-download/${invoice.key}/`}
            download target="_blank">
            <Icon name='download'/>
          </a>
          }
        </Table.Cell>
      </Table.Row>
    )
  }
}

class Invoices extends Component {

  componentDidMount() {
    const {companies, invoices} = this.props;

    if (!invoices.loaded && !invoices.loading) {
      this.props.dispatch({
        type: INVOICE_LIST,
        companyKey: companies.currentCompanyKey
      });
    }
    window.scrollTo(0, 0);
  }


  render() {
    const {t, companies: {currentCompanyKey: companyKey}, invoices} = this.props;

    if (!invoices.loaded) {
      return <Loading/>
    }

    if(invoices.loaded && !invoices.data) {
      return (
        <Container text className='main-content'>
          <Header as='h2'>
            {t('invoices.noData.title')}
          </Header>
          <p>{t('invoices.noData.description')}</p>
        </Container>
      );
    }

    return (
      <Table celled striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>{t('invoices.table.from')}</Table.HeaderCell>
            <Table.HeaderCell>{t('invoices.table.to')}</Table.HeaderCell>
            <Table.HeaderCell>{t('invoices.table.netRevenue')}</Table.HeaderCell>
            <Table.HeaderCell>{t('invoices.table.status')}</Table.HeaderCell>
            <Table.HeaderCell>{t('invoices.table.pdf')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {invoices.data &&
          Object.keys(invoices.data).map((key, index) => {
            const invoice = invoices.data[key];
            return <InvoiceRow
              invoice={invoice}
              companyKey={companyKey}
              key={key}
              index={index}
              t={t}
            />
          })
          }
        </Table.Body>
      </Table>
    );
  }
}


const mapStateToProps = ({companies, invoices}) => ({companies, invoices});
export default connect(mapStateToProps)(withTranslation()(Invoices));