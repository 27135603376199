import {call, put, take} from 'redux-saga/effects';

import apiClient from "../api-client";
import {INVOICE_LIST, INVOICE_LIST_FAIL, INVOICE_LIST_OK} from "../actions";


function* getInvoiceListWorker({companyKey}) {
  try {
    const response = yield call(
      apiClient.get,
      `companies/${companyKey}/invoices/`
    );
    yield put({
      type: INVOICE_LIST_OK,
      payload: response.data
    });
  } catch (error) {

    yield put({
      type: INVOICE_LIST_FAIL, payload: error.response.data
    });
  }
}

export function* getInvoiceList() {
  while (true) {
    const request = yield take(INVOICE_LIST);
    yield call(getInvoiceListWorker, request);
  }
}

