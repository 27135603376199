import React from 'react';
import {connect} from "react-redux";
import {Redirect, Route} from "react-router-dom";


const PrivateRoute = ({auth, component: Component, ...rest}) => (
  <Route {...rest} render={(props) => {
    if (!auth.authenticated) {
      return <Redirect to='/login'/>;
    }
    return <Component {...props} />;
  }}/>
);

const mapStateToProps = ({auth}) => ({auth});
export default connect(mapStateToProps)(PrivateRoute);




