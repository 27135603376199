import {call, put, take} from 'redux-saga/effects';

import apiClient from "../api-client";
import {
  ACCOUNT_GET,
  ACCOUNT_GET_FAIL,
  ACCOUNT_GET_OK,
  ACCOUNT_PASSWORD_FORGOT,
  ACCOUNT_PASSWORD_FORGOT_FAIL,
  ACCOUNT_PASSWORD_FORGOT_OK,
  ACCOUNT_PASSWORD_PUT,
  ACCOUNT_PASSWORD_PUT_FAIL,
  ACCOUNT_PASSWORD_PUT_OK,
  ACCOUNT_PASSWORD_RESET,
  ACCOUNT_REGISTER,
  ACCOUNT_REGISTER_FAIL,
  ACCOUNT_REGISTER_OK,
  AUTH_LOGIN_OK,
} from "../actions";


function* getAccountWorker() {
  try {
    const response = yield call(apiClient.get, 'account/');
    yield put({
      type: ACCOUNT_GET_OK, payload: response.data
    });
  } catch (error) {
    yield put({
      type: ACCOUNT_GET_FAIL, payload: error.response
    });
  }
}

export function* getAccount() {
  while (true) {
    yield take(ACCOUNT_GET);
    yield call(getAccountWorker);
  }
}


export function* registerAccountWorker({data, resolve, reject}) {
  try {
    const response = yield call(apiClient.post, `auth/register/`, data);
    yield put({type: ACCOUNT_REGISTER_OK, payload: response.data});
    yield put({type: AUTH_LOGIN_OK, payload: response.data});
    yield call(resolve, response.data);
  } catch (error) {
    yield put({
      type: ACCOUNT_REGISTER_FAIL,
      payload: error.response.data
    });
    yield call(reject, {errors: error.response.data});
  }
}

export function* registerAccount() {
  while (true) {
    const request = yield take(ACCOUNT_REGISTER);
    yield call(registerAccountWorker, request);
  }
}


function* forgotPasswordWorker({data, resolve, reject}) {
  try {
    const response = yield call(apiClient.post, 'auth/password/reset/', data);
    yield put({type: ACCOUNT_PASSWORD_FORGOT_OK, payload: response.data});
    yield call(resolve);
  } catch (error) {
    yield put({
      type: ACCOUNT_PASSWORD_FORGOT_FAIL,
      payload: error.response.data
    });
    yield call(reject, {errors: error.response.data});
  }
}

export function* forgotPassword() {
  while (true) {
    const request = yield take(ACCOUNT_PASSWORD_FORGOT);
    yield call(forgotPasswordWorker, request);
  }
}


export function* sendPasswordResetWorker({data, resolve, reject}) {
  try {
    const response = yield call(apiClient.post, 'auth/password/reset/confirm/', data);
    yield call(resolve, response.data);
  } catch (error) {
    yield call(reject, {errors: error.response.data});
  }
}

export function* sendPasswordReset() {
  while (true) {
    const request = yield take(ACCOUNT_PASSWORD_RESET);
    yield call(sendPasswordResetWorker, request);
  }
}


function* changePasswordWorker({data, resolve, reject}) {
  try {
    const response = yield call(apiClient.post, 'auth/password/change/', data);
    yield put({type: ACCOUNT_PASSWORD_PUT_OK, payload: response.data});
    yield call(resolve);
  } catch (error) {
    yield put({
      type: ACCOUNT_PASSWORD_PUT_FAIL,
      payload: error.response.data
    });
    yield call(reject, {errors: error.response.data});
  }
}

export function* changePassword() {
  while (true) {
    const request = yield take(ACCOUNT_PASSWORD_PUT);
    yield call(changePasswordWorker, request);
  }
}
