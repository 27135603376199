import React, {Component, Fragment} from 'react';
import MainMenu from "./main-menu";
import MainFooter from "./main-footer";
import {connect} from 'react-redux';
import Loading from "../ui/loading";
import TermsAndConditions from "./settings/terms-and-conditions";
import Dashboard from "./dashboard";
import CompanySettings from "./settings";

import {
  ACCOUNT_GET,
  COMPANY_GET,
  COMPANY_LIST,
  COMPANY_SET_CURRENT_KEY
} from "../actions";
import Settings from "./settings";
import {Route, withRouter} from "react-router-dom";
import {Container} from "semantic-ui-react";
import Invoices from './invoices';
import Games from './games';
import GameDetails from './games/game-details';


class Main extends Component {

  componentDidMount() {
    const {auth: {authenticated}, account, companies} = this.props;

    if (authenticated && !account.loaded && !account.loading) {
      this.props.dispatch({type: ACCOUNT_GET});
    }
    if (authenticated && !companies.loaded && !companies.loading) {
      this.props.dispatch({type: COMPANY_LIST});
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {auth: {authenticated}, account, companies} = this.props;
    if (!prevProps.authenticated && authenticated) {
      //
      if (!account.loaded && !account.loading) {
        this.props.dispatch({type: ACCOUNT_GET});
      }
      if (!companies.loaded && !companies.loading) {
        if (!companies.error) {
          this.props.dispatch({type: COMPANY_LIST});
        }
      }
      if (!prevProps.companies.loaded && companies.loaded) {
        let key;
        if (companies.currentCompanyKey) {
          key = companies.currentCompanyKey;
        } else {
          // first company key
          key = companies.data[0].key;
          this.props.dispatch({type: COMPANY_SET_CURRENT_KEY, payload: key});
        }
        this.props.dispatch({type: COMPANY_GET, key});
      }
      if (prevProps.companies.currentCompanyKey !== companies.currentCompanyKey) {
        this.props.dispatch({
          type: COMPANY_GET,
          key: companies.currentCompanyKey
        });
      }
    }
  }


  render() {
    const {account, company} = this.props;

    if (!company.loaded && company.error) {
      // 404 admin user has no company
      return (
        <Container text className='main-content'>
          <p>No company found.</p>
        </Container>
      )
    }

    if (!account.loaded || !company.loaded) {
      return <Loading/>;
    }

    let content;
    if (!company.data.agreedToTerms) {
      content = <TermsAndConditions/>;
    } else if (!company.data.completed) {
      content = <CompanySettings/>;
    }


    return (
      <Fragment>
        <MainMenu/>
        <Container className='main-content'>
          {content}
          {!content &&
          <Fragment>
            <Route exact path="/invoices" component={Invoices}/>
            <Route exact path="/games" component={Games}/>
            <Route exact path="/games/:gameKey" component={GameDetails}/>
            <Route exact path="/settings/" component={Settings}/>
            <Route exact path="/" component={Dashboard}/>
          </Fragment>

          }
        </Container>
        <MainFooter/>
      </Fragment>
    );
  }
}

const mapStateToProps = ({auth, account, companies, company}) => ({
  auth,
  account,
  companies,
  company
});
export default withRouter(connect(mapStateToProps)(Main));